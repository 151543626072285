import Vue from 'vue';
export default Vue.extend({
    data() {
        return {
            show: false,
        };
    },
    created() {
        // Pageshow event to prevent chrome issues with backnav
        window.addEventListener('pageshow', () => {
            this.show = true;
        });
    },
});
