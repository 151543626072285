import Vue from 'vue';
export default Vue.extend({
    methods: {
        /**
         * When primary image loads, fire load on secondary image
         */
        onPrimaryLoad() {
            const pairing = this.$refs.pairing;
            if (pairing && typeof pairing.loadImage === 'function') {
                pairing.loadImage();
            }
        },
    },
});
