import Vue from 'vue';
export default Vue.extend({
    computed: {
        options() {
            return this.$store.state.system_configuration.options.postWeights;
        },
        selection: {
            get() {
                return this.$store.state.system_configuration.selections.postWeight;
            },
            set(value) {
                this.$store.dispatch('system_configuration/postWeightSelection', value);
            },
        },
    },
});
