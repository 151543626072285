import Vue from 'vue';
import './Contact';
import CookiesNotice from './CookiesNotice.vue';
import './Library';
import LoadMoreList from './LoadMoreList.vue';
import NewsIndex from './News/NewsIndex.vue';
import PageShowLoader from './PageShowLoader.vue';
import PairedFinishSwatch from './PairedFinishSwatch.vue';
import PatternFinishPreview from './PatternFinishPreview';
import PatternsFilterableGallery from './PatternsFilterableGallery';
import RepSearch from './RepSearch/RepSearch.vue';
import './SampleRequest';
import './Work/Gallery';
Vue.component('RepSearch', RepSearch);
Vue.component('LoadMoreList', LoadMoreList);
Vue.component('PatternsFilterableGallery', PatternsFilterableGallery);
Vue.component('PatternFinishPreview', PatternFinishPreview);
Vue.component('NewsIndex', NewsIndex);
Vue.component('PageShowLoader', PageShowLoader);
Vue.component('PairedFinishSwatch', PairedFinishSwatch);
Vue.component('CookiesNotice', CookiesNotice);
