import Vue from 'vue';
import { FinishSelect, Preview } from './Components';
// @vue/component
export default Vue.extend({
    components: {
        FinishSelect,
        Preview,
    },
    props: {
        /**
         * The available finish categories (containing finishes)
         */
        finish_categories: {
            type: Array,
            required: true,
        },
        /**
         * The pattern for use in the component
         */
        pattern: {
            type: Object,
            required: true,
        },
    },
    /**
     * Reactive data
     */
    data() {
        return {
            /**
             * The current active finish
             */
            active_finish: null,
            loaded: false,
        };
    },
    created() {
        if (this.finish_categories
            && this.finish_categories.length
            && this.finish_categories[0].finishes
            && this.finish_categories[0].finishes.length) {
            // eslint-disable-next-line prefer-destructuring
            this.active_finish = this.finish_categories[0].finishes[0];
        }
    },
    methods: {
        /**
         * Handle the event from a finish selection
         */
        onSelectFinish(finish) {
            this.active_finish = finish;
        },
        onPatternLoaded() {
            this.loaded = true;
        },
    },
});
