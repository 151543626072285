/* eslint-disable vue/prop-name-casing */
import Vue from 'vue';
import { PatternsFilterableGalleryState } from './_store';
export default Vue.extend({
    props: {
        patterns: {
            type: Array,
            required: true,
        },
        /**
         * The tooltips for the various attribute filters.
         *
         * If a filter key is not within this object, the filter label will not show a tooltip
         */
        filter_tooltips: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            filter_takeover_active: false,
            sort_takeover_active: false,
            loading: true,
        };
    },
    created() {
        if (!this.$store.state.patterns_filterable_gallery) {
            this.$store.registerModule('patterns_filterable_gallery', PatternsFilterableGalleryState);
        }
        // Pageshow event to prevent chrome issues with backnav
        window.addEventListener('pageshow', () => {
            const payload = {
                patterns: this.patterns,
                tooltips: this.filter_tooltips,
            };
            this.$store.dispatch('patterns_filterable_gallery/init', payload)
                .then(() => {
                this.loading = false;
            });
        });
    },
    methods: {
        filtersTakeoverOpen() {
            this.$store.commit('patterns_filterable_gallery/cacheFilters');
            this.filter_takeover_active = true;
        },
        filtersTakeoverClose(apply) {
            if (!apply) {
                this.$store.commit('patterns_filterable_gallery/restoreCachedFilters');
            }
            this.filter_takeover_active = false;
        },
        sortTakeoverOpen() {
            this.sort_takeover_active = true;
        },
        sortTakeoverClose() {
            this.sort_takeover_active = false;
        },
    },
});
