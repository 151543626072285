/* eslint-disable camelcase,no-plusplus */
import Vue from 'vue';
import ScrollHelpers from '../Support/ScrollHelpers';
export default Vue.extend({
    props: {
        /**
         * Whether, upon close, to return to the prior document scroll position
         */
        return_to_scroll_location: {
            type: Boolean,
            default: false,
        },
    },
    /**
     * Reactive data
     */
    data() {
        return {
            /**
             * The logged document scroll upon open
             */
            previous_scroll: 0,
            /**
             * Any classes removed from the root element upon creation
             */
            previous_root_classes: null,
            parent_nodes: [],
        };
    },
    /**
     * Upon component creation...
     *
     * 1. Log the document scroll position
     * 2. Remove root classes that we don't want
     * 3. Hide all direct children of app root element
     */
    created() {
        const root = this.$root;
        if (!root) {
            return;
        }
        const current_scroll = ScrollHelpers.getCurrentScroll();
        if (current_scroll) {
            this.previous_scroll = current_scroll;
        }
        const root_element = root.$el;
        this.previous_root_classes = root_element.getAttribute('class');
        root_element.removeAttribute('class');
        const root_children = root_element.children;
        for (let i = 0; i < root_children.length; i++) {
            const rootChild = root_children[i];
            rootChild.style.display = 'none';
        }
    },
    /**
     * On component mount...
     *
     * 1. Append this component's element to app root element
     * 2. Reset document scroll
     */
    mounted() {
        const root = this.$root;
        if (!root) {
            return;
        }
        const root_element = root.$el;
        root_element.appendChild(this.$el);
        this.parent_nodes = this.getParentNodes();
        this.applyParentClasses();
        ScrollHelpers.scrollTo(0);
    },
    /**
     * Before component is destroyed...
     *
     * 1. Restore root element classes removed upon creation of takeover.
     * 2. Un-hide root element child elements
     */
    beforeDestroy() {
        const root = this.$root;
        if (!root) {
            return;
        }
        const root_element = root.$el;
        if (this.previous_root_classes) {
            root_element.setAttribute('class', this.previous_root_classes);
        }
        const root_children = root.$el.children;
        for (let i = 0; i < root_children.length; i++) {
            const rootChild = root_children[i];
            rootChild.style.removeProperty('display');
        }
        this.removeParentClasses();
    },
    /**
     * Upon component destruction...
     *
     * 1. If configured to do so, restore the
     * original document scroll position. Otherwise reset scroll
     *
     * Component element will be removed via its destruction
     */
    destroyed() {
        const root = this.$root;
        if (!root) {
            return;
        }
        ScrollHelpers.scrollTo(this.return_to_scroll_location ? this.previous_scroll : 0);
    },
    methods: {
        getElementClasses() {
            const el = this.$el;
            return el.classList;
        },
        getParentNodes() {
            const nodes = [];
            let parentNode = this.$el.parentElement;
            while (parentNode) {
                nodes.push(parentNode);
                parentNode = parentNode.parentElement;
            }
            return nodes;
        },
        /**
         * Close the takeover
         */
        close() {
            this.$emit('close');
        },
        /**
         * Bubble up parent node tree and give each node active classes
         */
        applyParentClasses() {
            const element_classes = this.getElementClasses();
            const nodes = this.parent_nodes;
            for (let j = 0; j < nodes.length; j++) {
                const node = nodes[j];
                for (let i = 0; i < element_classes.length; i++) {
                    const element_class = element_classes[i];
                    node.classList.add(`${element_class}--active`);
                }
            }
        },
        /**
         * Bubble up parent node tree and remove each node active classes
         */
        removeParentClasses() {
            const element_classes = this.getElementClasses();
            const nodes = this.parent_nodes;
            for (let j = 0; j < nodes.length; j++) {
                const node = nodes[j];
                for (let i = 0; i < element_classes.length; i++) {
                    const element_class = element_classes[i];
                    node.classList.remove(`${element_class}--active`);
                }
            }
        },
    },
});
