import axios from 'axios';
import { getMessageFromError } from '../../Framework/Support/Forms';
import { GaEventReporter } from '../../Support/GaEventReporter';
export class State {
    constructor() {
        this.application = null;
        this.selections = {
            system: null,
            postWeight: null,
            basePlate: null,
            hardware: null,
            pattern: null,
            panelFinish: null,
            structureFinish: null,
        };
        this.options = {
            systems: [],
            patterns: [],
            panelFinishes: [],
            structureFinishes: [],
            postWeights: [],
            basePlates: [],
            hardware: [],
        };
        this.disabledFields = {
            systems: false,
            patterns: false,
            panelFinishes: false,
            structureFinishes: false,
            postWeights: false,
            basePlates: false,
            hardware: false,
        };
        this.gaEvents = {
            requestStarted: null,
            requestCompleted: null,
            specDownloaded: null,
        };
        this.estimatedCosts = {};
        this.systemRenderings = {};
        this.contactOptions = {};
        this.requestQuoteActive = false;
        this.endpoints = {};
        this.moduleTooltips = {};
    }
}
const actions = {
    reportGaEvent(context, key) {
        const gaEvent = context.state.gaEvents[key];
        if (gaEvent) {
            GaEventReporter.report(gaEvent);
        }
    },
    startRequestQuote(context) {
        context.dispatch('reportGaEvent', 'requestStarted');
        context.commit('setShowModal', true);
    },
    downloadSpec(context) {
        context.dispatch('reportGaEvent', 'specDownload');
        return new Promise((resolve, reject) => {
            axios.post(context.state.endpoints.downloadSpec, {
                projectData: context.getters.exportData,
            }).then((response) => {
                window.open(response.data, '_blank');
                resolve();
            }).catch((response) => {
                reject(getMessageFromError(response) || 'There was a problem submitting your request. Please double check all inputs and try again.');
            });
        });
    },
    requestQuote(context, requestFormData) {
        return new Promise((resolve, reject) => {
            axios.post(context.state.endpoints.requestQuote, {
                projectData: context.getters.exportData,
                contactInformation: requestFormData,
            }).then(() => {
                context.dispatch('reportGaEvent', 'requestCompleted');
                resolve('Your request for a quote has been sent.');
            }).catch((response) => {
                reject(getMessageFromError(response) || 'There was a problem submitting your request. Please double check all inputs and try again.');
            });
        });
    },
    init(context, payload) {
        context.commit('setOptions', payload.options);
        context.commit('setSystemRenderings', payload.systemRenderings);
        context.commit('setApplication', payload.application);
        context.commit('setEndpoints', payload.endpoints);
        context.commit('setContactOptions', payload.contactOptions);
        context.commit('setEstimatedCosts', payload.estimatedCosts);
        context.commit('setGaEvents', payload.gaEvents);
        context.commit('setModuleTooltips', payload.moduleTooltips);
    },
    postWeightSelection(context, payload) {
        context.commit('setPostWeightSelection', payload);
        let fieldsDisabled = false;
        if (payload.key === 'heavy') {
            context.commit('setBasePlateSelection', context.getters.basePlateOptionByKey('square'));
            context.commit('setHardwareSelection', context.getters.hardwareOptionByKey('pbi'));
            fieldsDisabled = true;
        }
        const basePlatePayload = {
            fields: ['basePlates', 'hardware'],
            disabled: fieldsDisabled,
        };
        context.commit('disableFields', basePlatePayload);
    },
};
const getters = {
    validationErrors(state) {
        const keyMap = {
            panelFinish: 'panel finish',
            structureFinish: 'structure finish',
            postWeight: 'post weight',
            basePlate: 'base plate',
        };
        const optionMap = {
            system: 'systems',
            pattern: 'patterns',
            panelFinish: 'panelFinishes',
            structureFinish: 'structureFinishes',
            postWeight: 'postWeights',
            basePlate: 'basePlates',
            hardware: 'hardware',
        };
        return Object.keys(state.selections).reduce((carry, key) => {
            if (state.selections[key] === null) {
                const optionKey = optionMap[key];
                const optionSet = state.options[optionKey] || [];
                if (optionSet.length) {
                    const outputKey = keyMap[key] || key;
                    carry.push(`Please select a ${outputKey} to continue`);
                }
            }
            return carry;
        }, []);
    },
    basePlateOptionByKey(state) {
        return (key) => {
            for (let i = 0; i < state.options.basePlates.length; i += 1) {
                const option = state.options.basePlates[i];
                if (option.key === key) {
                    return option;
                }
            }
            return null;
        };
    },
    hardwareOptionByKey(state) {
        return (key) => {
            for (let i = 0; i < state.options.hardware.length; i += 1) {
                const option = state.options.hardware[i];
                if (option.key === key) {
                    return option;
                }
            }
            return null;
        };
    },
    // eslint-disable-next-line no-shadow
    activeEstimatedCost(state, getters) {
        const availableKeys = Object.keys(state.estimatedCosts);
        const activeSelectionsKey = getters.activeEstimatedCostKey;
        const activeIndex = availableKeys.indexOf(activeSelectionsKey);
        if (activeIndex !== -1) {
            return state.estimatedCosts[activeSelectionsKey];
        }
        return null;
    },
    activeEstimatedCostKey(state) {
        if (state.application === 'shade') {
            return state.selections.system ? state.selections.system.key : '';
        }
        let system = '';
        let postWeight = '';
        if (state.selections.system) {
            system = state.selections.system.key;
        }
        if (state.selections.postWeight) {
            postWeight = state.selections.postWeight.key;
        }
        return [system, postWeight].join('|');
    },
    activeOptionsKey(state) {
        if (state.application === 'shade') {
            return state.selections.system ? state.selections.system.key : '';
        }
        let system = '';
        let postWeight = '';
        let basePlate = '';
        let hardware = '';
        if (state.selections.system) {
            system = state.selections.system.key;
        }
        if (state.selections.postWeight) {
            postWeight = state.selections.postWeight.key;
        }
        if (state.selections.basePlate) {
            basePlate = state.selections.basePlate.key;
        }
        if (state.selections.hardware) {
            hardware = state.selections.hardware.key;
        }
        return [system, postWeight, basePlate, hardware].join('|');
    },
    // eslint-disable-next-line no-shadow
    activeSystemRendering(state, getters) {
        const systemRenderingKeys = Object.keys(state.systemRenderings);
        const activeSelectionsKey = getters.activeOptionsKey;
        const activeIndex = systemRenderingKeys.indexOf(activeSelectionsKey);
        if (activeIndex !== -1) {
            return state.systemRenderings[activeSelectionsKey];
        }
        return null;
    },
    activePanelSize(state) {
        const { system } = state.selections;
        if (system) {
            return system.panelSize;
        }
        const systemSizes = [];
        for (let i = 0; i < state.options.systems.length; i += 1) {
            const systemOption = state.options.systems[i];
            const optionPanelSize = systemOption.panelSize;
            if (systemSizes.indexOf(optionPanelSize) === -1) {
                systemSizes.push(optionPanelSize);
            }
        }
        if (systemSizes.length === 1) {
            return systemSizes[0];
        }
        return null;
    },
    activeSystemName(state) {
        const { system } = state.selections;
        return system ? system.descriptionName : null;
    },
    activeSystemSubname(state) {
        const result = [];
        if (state.selections.postWeight) {
            result.push(state.selections.postWeight.descriptionName);
        }
        if (state.selections.basePlate) {
            result.push(state.selections.basePlate.descriptionName);
        }
        if (state.selections.hardware) {
            result.push(state.selections.hardware.descriptionName);
        }
        return result.join(' | ');
    },
    // eslint-disable-next-line no-shadow
    activePatternRendering(state, getters) {
        const { pattern } = state.selections;
        if (!pattern) {
            return null;
        }
        const size = getters.activePanelSize || '4x8';
        return pattern.renderings[size];
    },
    // eslint-disable-next-line no-shadow
    exportData(state, getters) {
        const { activeSystemRendering, activePanelSize, activeSystemName, activeSystemSubname, activePatternRendering, } = getters;
        const { selections, options, application } = state;
        return {
            application,
            selections,
            options,
            activeSystemRendering,
            activePanelSize,
            activeSystemName,
            activeSystemSubname,
            activePatternRendering,
        };
    },
};
const mutations = {
    setSystemSelection(state, payload) {
        state.selections.system = payload;
    },
    setPatternSelection(state, payload) {
        state.selections.pattern = payload;
    },
    setPanelFinishSelection(state, payload) {
        state.selections.panelFinish = payload;
    },
    setStructureFinishSelection(state, payload) {
        state.selections.structureFinish = payload;
    },
    setPostWeightSelection(state, payload) {
        state.selections.postWeight = payload;
    },
    setBasePlateSelection(state, payload) {
        state.selections.basePlate = payload;
    },
    setHardwareSelection(state, payload) {
        state.selections.hardware = payload;
    },
    setOptions(state, payload) {
        state.options = payload;
    },
    setSystemRenderings(state, payload) {
        state.systemRenderings = payload;
    },
    disableFields(state, payload) {
        payload.fields.forEach((field) => {
            state.disabledFields[field] = payload.disabled;
        });
    },
    setApplication(state, payload) {
        state.application = payload;
    },
    setShowModal(state, payload) {
        state.requestQuoteActive = payload;
    },
    setEndpoints(state, payload) {
        state.endpoints = payload;
    },
    setContactOptions(state, payload) {
        state.contactOptions = payload;
    },
    setEstimatedCosts(state, payload) {
        state.estimatedCosts = payload;
    },
    setGaEvents(state, payload) {
        state.gaEvents = payload;
    },
    setModuleTooltips(state, payload) {
        if (typeof payload === 'object') {
            state.moduleTooltips = payload;
        }
    },
};
export const SystemConfigurationState = {
    namespaced: true,
    state: new State(),
    actions,
    getters,
    mutations,
};
