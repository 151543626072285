import Vue from 'vue';
export default Vue.extend({
    computed: {
        available_filters() {
            return this.$store.state.patterns_filterable_gallery.available_sort_filters;
        },
        active_sort_filter: {
            get() {
                return this.$store.state.patterns_filterable_gallery.active_sort_filter;
            },
            set(value) {
                this.$store.commit('patterns_filterable_gallery/setSortFilter', value);
            },
        },
    },
});
