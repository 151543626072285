import Vue from 'vue';
export default Vue.extend({
    data() {
        return {
            showModal: true,
        };
    },
    methods: {
        close() {
            this.showModal = false;
            this.$emit('close');
        },
    },
});
