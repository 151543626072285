import gsap from 'gsap';
import Vue from 'vue';
export default Vue.extend({
    methods: {
        /**
         * Create the timeline instance
         */
        createTimeline(el, done) {
            const timeline = gsap.timeline({
                paused: true,
                onComplete() {
                    done();
                },
                onReverseComplete() {
                    done();
                },
            });
            el.childNodes.forEach((node) => {
                if (node instanceof HTMLElement) {
                    timeline.fromTo(node, {
                        height: 0,
                        opacity: 0,
                    }, {
                        height: node.clientHeight,
                        opacity: 1,
                        ease: 'none',
                        duration: 0.05,
                    });
                }
            });
            return timeline;
        },
        /**
         * Enter active
         */
        enter(el, done) {
            const timeline = this.createTimeline(el, done);
            timeline.play();
        },
        /**
         * Enter concluded
         */
        afterEnter(el) {
            el.childNodes.forEach((node) => {
                if (node instanceof HTMLElement) {
                    node.removeAttribute('style');
                }
            });
        },
        /**
         * Enter cancelled
         */
        enterCancelled(el) {
            el.childNodes.forEach((node) => {
                if (node instanceof HTMLElement) {
                    node.removeAttribute('style');
                }
            });
        },
        /**
         * Leave active
         */
        leave(el, done) {
            const timeline = this.createTimeline(el, done);
            timeline.reverse(0);
        },
        /**
         * Leave concluded
         */
        afterLeave(el) {
            el.childNodes.forEach((node) => {
                if (node instanceof HTMLElement) {
                    node.removeAttribute('style');
                }
            });
        },
        /**
         * Leave canceled
         */
        leaveCancelled(el) {
            el.childNodes.forEach((node) => {
                if (node instanceof HTMLElement) {
                    node.removeAttribute('style');
                }
            });
        },
    },
});
