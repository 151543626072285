export default class WorkGalleryTile {
    constructor(data) {
        this.adminNotices = data.adminNotices;
        this.applicationIds = data.applicationIds;
        this.createdAtTs = data.createdAtTs;
        this.id = data.id;
        this.industryIds = data.industryIds;
        this.infoWindowImage = data.infoWindowImage;
        this.isNew = data.isNew;
        this.locationDescription = data.locationDescription;
        this.locations = data.locations;
        this.markerImage = data.markerImage;
        this.tileImage = data.tileImage;
        this.title = data.title;
        this.url = data.url;
    }
}
