import { SortingService } from '../../../../Framework/Services/SortingService';
export class WorkGallerySortingService extends SortingService {
    static sort(caseStudies, sortFilter) {
        const sortFilterEst = sortFilter || 'isNew_desc';
        const split = sortFilterEst.split('_');
        const field = split[0] || 'isNew';
        const direction = split[1] || 'desc';
        const numericFields = [
            'createdAtTs',
        ];
        if (field === 'isNew') {
            return caseStudies.sort((a, b) => {
                if (a.isNew === b.isNew) {
                    return WorkGallerySortingService.compareNumeric(b, a, 'createdAtTs');
                }
                if (a.isNew) {
                    return -1;
                }
                return 1;
            });
        }
        if (numericFields.indexOf(field) !== -1) {
            return WorkGallerySortingService.sortNumeric(caseStudies, field, direction);
        }
        return WorkGallerySortingService.sortAlpha(caseStudies, field, direction);
    }
}
export default WorkGallerySortingService;
