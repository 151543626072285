import Vue from 'vue';
export default Vue.extend({
    data() {
        return {
            showImage: false,
            showPlaceholder: true,
        };
    },
    mounted() {
        if (typeof window.IntersectionObserver === 'undefined') {
            this.loadImage();
            return;
        }
        const observerOptions = {
        // rootMargin: '-300px',
        };
        const observerCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.loadImage();
                    observer.disconnect();
                }
            });
        };
        const imageObserver = new IntersectionObserver(observerCallback, observerOptions);
        imageObserver.observe(this.$el);
    },
    methods: {
        onPictureLoad() {
            this.showPlaceholder = false;
        },
        loadImage() {
            this.showImage = true;
        },
    },
});
