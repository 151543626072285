export var PatternsFilterableGalleryFilteringService;
(function (PatternsFilterableGalleryFilteringService) {
    //
    let FilterKey;
    (function (FilterKey) {
        FilterKey["OPENNESS"] = "openness_percentage";
        FilterKey["SHADE"] = "shade";
        FilterKey["PRIVACY"] = "privacy";
        FilterKey["BIOMIMIC"] = "biomimicry";
        FilterKey["STRENGTH"] = "structural_strength";
        FilterKey["SMALL_HOLES"] = "small_hole_size";
        FilterKey["MESH"] = "mesh";
        FilterKey["NAME"] = "name";
    })(FilterKey = PatternsFilterableGalleryFilteringService.FilterKey || (PatternsFilterableGalleryFilteringService.FilterKey = {}));
    //
    let NumericFilterKey;
    (function (NumericFilterKey) {
        NumericFilterKey["OPENNESS"] = "openness_percentage";
        NumericFilterKey["SHADE"] = "shade";
        NumericFilterKey["PRIVACY"] = "privacy";
        NumericFilterKey["BIOMIMIC"] = "biomimicry";
        NumericFilterKey["STRENGTH"] = "structural_strength";
        NumericFilterKey["SMALL_HOLES"] = "small_hole_size";
        NumericFilterKey["MESH"] = "mesh";
    })(NumericFilterKey = PatternsFilterableGalleryFilteringService.NumericFilterKey || (PatternsFilterableGalleryFilteringService.NumericFilterKey = {}));
    //
    let AlphaFilterKey;
    (function (AlphaFilterKey) {
        AlphaFilterKey["NAME"] = "name";
    })(AlphaFilterKey = PatternsFilterableGalleryFilteringService.AlphaFilterKey || (PatternsFilterableGalleryFilteringService.AlphaFilterKey = {}));
})(PatternsFilterableGalleryFilteringService || (PatternsFilterableGalleryFilteringService = {}));
