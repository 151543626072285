import Vue from 'vue';
export default Vue.extend({
    computed: {
        filters_set() {
            return this.$store.getters['patterns_filterable_gallery/filters_set'];
        },
    },
    methods: {
        resetFilters() {
            this.$store.commit('patterns_filterable_gallery/resetFilters');
        },
    },
});
