import Vue from 'vue';
export default Vue.extend({
    props: {
        pattern: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            debug_mode: false,
            loaded: false,
        };
    },
    mounted() {
        if (typeof window.IntersectionObserver === 'undefined') {
            this.loadImage();
            return;
        }
        const observerOptions = {
        // rootMargin: '-300px',
        };
        const observerCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.loadImage();
                    observer.disconnect();
                }
            });
        };
        const imageObserver = new IntersectionObserver(observerCallback, observerOptions);
        imageObserver.observe(this.$el);
    },
    methods: {
        loadImage() {
            const container = this.$refs.imageContainer;
            if (container instanceof HTMLElement) {
                container.innerHTML = this.pattern.picture;
                const img = container.querySelector('img');
                if (img) {
                    if (img.complete) {
                        this.loaded = true;
                        return;
                    }
                    img.addEventListener('load', () => {
                        this.loaded = true;
                    });
                }
            }
        },
    },
});
