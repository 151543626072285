import Vue from 'vue';
export default Vue.extend({
    props: {
        /**
         * The file for the component
         */
        file: {
            type: Object,
            required: true,
        },
        /**
         * The current free fileter active on the library
         */
        activeFreeFilter: {
            type: String,
            default: '',
        },
        /**
         * Whether link gates are active
         */
        gateActive: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        /**
         * Respond to gate link gate-completed event
         */
        onGateCompleted() {
            this.$emit('gate-completed');
        },
        /**
         * Whether a link should appear as gated
         */
        linkGated(link) {
            if (!this.gateActive) {
                return false;
            }
            return !!link.gate;
        },
    },
});
