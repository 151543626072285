export const elementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    const { clientHeight } = document.documentElement;
    return rect.top > 0 && rect.top < clientHeight && rect.bottom < clientHeight && rect.bottom > 0;
};
export const ensureDocumentLoaded = () => new Promise((resolve) => {
    if (document.readyState === 'complete') {
        resolve();
        return;
    }
    window.addEventListener('load', () => {
        resolve();
    });
});
