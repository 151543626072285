import Vue from 'vue';
import FormValidator from '../../Support/FormValidator';
export default Vue.extend({
    filters: {
        label(field) {
            return `${field.label}${field.required ? '*' : ''}`;
        },
    },
    props: {
        config: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            formData: {},
            validator: new FormValidator(this.config.validation.rules, this.config.validation.messages),
            showErrors: false,
            submitting: false,
            repSearchModalActive: false,
        };
    },
    computed: {
        fields() {
            return this.config.fields;
        },
        /**
         * The list of currently active fields
         */
        activeFields() {
            return []
                .concat(this.fields.common)
                .concat(this.fields.type)
                .concat(this.conditionalFields);
        },
        /**
         * The fields that should appear based on the user's role
         */
        conditionalFields() {
            const customerType = this.formData.customer_type;
            if (customerType && typeof customerType === 'string') {
                return this.fields.conditional.filter((field) => field.customer_types
                    && field.customer_types.indexOf(customerType) !== -1);
            }
            return this.fields.conditional.filter((field) => field.name === 'how_can_we_help');
        },
        targetInstallDateWithinWindow() {
            const validationErrors = 'target_install_date' in this.validationResult.errors ? this.validationResult.errors.target_install_date : false;
            if (validationErrors) {
                const keys = Object.keys(validationErrors);
                if (keys.indexOf('required') !== -1 || keys.indexOf('date') !== -1) {
                    return true;
                }
            }
            const min = this.config.misc.six_weeks_out * 1000;
            const split = this.formData.target_install_date.split('/');
            const month = parseInt(split[0], 10);
            const day = parseInt(split[1], 10);
            const year = parseInt(`20${split[2]}`, 10);
            const date = new Date(Date.UTC(year, month - 1, day));
            const time = date.getTime();
            return time >= min;
        },
        /**
         * The error for the given field, if there is one.
         */
        fieldError() {
            return (field) => {
                if (!this.showErrors) {
                    return null;
                }
                const validationErrors = this.validationResult.errors;
                const fieldErrors = validationErrors[field.name] || {};
                const errorKeys = Object.keys(fieldErrors);
                if (errorKeys.length) {
                    return fieldErrors[errorKeys[0]];
                }
                return null;
            };
        },
        fieldHasRepSearch() {
            return (field) => {
                if (field.name === 'company_loc_state') {
                    if (this.formData.company_loc_state) {
                        const customerType = this.formData.customer_type;
                        const validCustomerTypes = [
                            'architect_designer',
                            'landscape_architect',
                            'interior_designer',
                            'contractor',
                        ];
                        if (typeof customerType === 'string' && validCustomerTypes.indexOf(customerType) !== -1) {
                            return true;
                        }
                    }
                }
                return false;
            };
        },
        showTimelineNotice() {
            return (field) => {
                if (field.name !== 'target_install_date') {
                    return false;
                }
                if (this.fieldError(field)) {
                    return false;
                }
                return !this.targetInstallDateWithinWindow;
            };
        },
        valid() {
            return this.validationResult.valid;
        },
        /**
         * Current validation state of the form
         */
        validationResult() {
            return this.validator.validate(this.activeFields, this.formData);
        },
    },
    /**
     * On create, get all available field names and
     * set their keys in formData to the initial field value
     */
    created() {
        const allFields = []
            .concat(this.fields.common)
            .concat(this.fields.type)
            .concat(this.fields.conditional);
        allFields.forEach((field) => {
            this.$set(this.formData, field.name, field.value);
        });
    },
    methods: {
        showRepSearchModal() {
            this.repSearchModalActive = true;
        },
        onSubmit(e) {
            if (!this.valid) {
                this.showErrors = true;
                e.preventDefault();
                return;
            }
            this.submitting = true;
        },
    },
});
