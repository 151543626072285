import mixins from 'vue-typed-mixins';
import SelectionModuleMixin from '../Mixins/SelectionModuleMixin';
// @vue/component
export default mixins(SelectionModuleMixin).extend({
    computed: {
        options() {
            return this.$store.state.system_configuration.options.basePlates;
        },
        selection: {
            get() {
                return this.$store.state.system_configuration.selections.basePlate;
            },
            set(value) {
                this.$store.commit('system_configuration/setBasePlateSelection', value);
            },
        },
    },
    methods: {
        optionDisabled(option) {
            return this.$store.state.system_configuration.disabledFields.basePlates
                && option !== this.selection;
        },
    },
});
