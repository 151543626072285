import { FilteringService } from '../../../../Framework/Services';
export class WorkGalleryFilteringService extends FilteringService {
    static filter(caseStudies, filters) {
        return caseStudies.filter((case_study) => {
            const { application, industry, free, } = filters;
            if (free
                && !WorkGalleryFilteringService.valuePassesStringFilter(case_study.title, free)) {
                return false;
            }
            if (application && case_study.applicationIds.indexOf(application) === -1) {
                return false;
            }
            if (industry && case_study.industryIds.indexOf(industry) === -1) {
                return false;
            }
            return true;
        });
    }
}
export default WorkGalleryFilteringService;
