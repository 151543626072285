/* eslint-disable camelcase,no-plusplus */
import Vue from 'vue';
let instance_id = 1;
export default Vue.extend({
    props: {
        conf: {
            type: Object,
            default: () => ({
                min: 0,
                max: 10,
            }),
        },
        value: {
            type: Object,
            required: false,
        },
        label: {
            type: String,
            required: false,
            default: 'Range',
        },
    },
    data() {
        return {
            local_value: {
                min: this.value ? this.value.min : this.conf.min,
                max: this.value ? this.value.max : this.conf.max,
            },
            recent: 'max',
            elements: {
                min: this.$refs.min,
                max: this.$refs.max,
            },
            id_basis: `input_min_max--${instance_id++}`,
        };
    },
    computed: {
        /**
         * The left offset for the fill (in whole number as a percent)
         */
        fill_left() {
            return this.progressPercent(this.local_value.min);
        },
        /**
         * The right offset for the fill (in whole number as a percent)
         */
        fill_right() {
            return 100 - this.progressPercent(this.local_value.max);
        },
        /**
         * Configuration of ticks for input
         */
        ticks() {
            if (typeof this.conf.step === 'number') {
                const result = [];
                const step_ratio = Math.floor((this.conf.max - this.conf.min) / this.conf.step);
                const ticks = step_ratio + 1;
                for (let i = 0; i < ticks; i++) {
                    const tick_value = this.conf.min + (i * this.conf.step);
                    result.push(this.progressPercent(tick_value));
                }
                return result;
            }
            return null;
        },
    },
    watch: {
        value(value) {
            this.setMax(value.max);
            this.setMin(value.min);
        },
        /**
         * Watch min value for change greater than max.
         * If such a change, update both values to new value
         */
        'local_value.min': function (value) {
            if (value > this.local_value.max) {
                this.setMax(value);
            }
        },
        /**
         * Watch max value for change less than min.  If such a change, update both values to new value
         */
        'local_value.max': function (value) {
            if (value < this.local_value.min) {
                this.setMin(value);
            }
        },
        local_value: {
            deep: true,
            handler(value) {
                this.$emit('input', value);
            },
        },
    },
    created() {
        if (this.isHandlesMergedMax()) {
            this.recent = 'min';
        }
    },
    mounted() {
        this.elements.min = this.$refs.min;
        this.elements.max = this.$refs.max;
        this.setIdBasis();
    },
    methods: {
        /**
         * Whether both handles have the same value at the max end of the range
         */
        isHandlesMergedMax() {
            return this.local_value.min === this.local_value.max
                && this.local_value.min === this.conf.max;
        },
        /**
         * Handle change on the max input value
         */
        maxChange(e) {
            const target = e.target;
            const value = parseFloat(target.value);
            this.setMax(value);
            this.recent = 'max';
        },
        /**
         * Handle change on the min input value
         */
        minChange(e) {
            const target = e.target;
            const value = parseFloat(target.value);
            this.setMin(value);
            this.recent = 'min';
        },
        /**
         * Get the progress percent based on a value
         */
        progressPercent(value) {
            const total_range = this.conf.max - this.conf.min;
            const progress = value - this.conf.min;
            return (progress / total_range) * 100;
        },
        /**
         * Set the basis for id attributes within component
         */
        setIdBasis() {
            if (this.$el) {
                const element_id = this.$el.getAttribute('id');
                if (element_id) {
                    this.id_basis = element_id;
                }
            }
        },
        /**
         * Set the current max value
         */
        setMax(value) {
            this.local_value.max = value;
            this.elements.max.value = value.toString();
        },
        /**
         * Set the current min value
         */
        setMin(value) {
            this.local_value.min = value;
            this.elements.min.value = value.toString();
        },
    },
});
