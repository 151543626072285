/* eslint-disable camelcase,max-len */
import Vue from 'vue';
// @vue/component
export default Vue.extend({
    props: {
        /**
         * The finish to apply to the preview
         */
        finish: {
            type: Object,
            required: false,
        },
        /**
         * The pattern for the preview
         */
        pattern: {
            type: Object,
            required: true,
        },
        /**
         * Config for the component
         */
        config: {
            type: Object,
            default: () => ({
                panel_width: 400,
                panel_height: 800,
                silhouette_offset: {
                    x: 43.478260869,
                    y: 28.260869565,
                },
                silhouette_width: 244.927536232,
                silhouette_height: 656.52173913,
                show_silhouette: true,
                finish_dimensions: {
                    width: 1000,
                    height: 1000,
                },
            }),
        },
    },
    data() {
        return {
            patternLoaded: false,
        };
    },
    computed: {
        /**
         * The dimensions of the preview
         */
        dimensions() {
            const silhouette_width_component = this.config.show_silhouette
                ? this.config.silhouette_width + this.config.silhouette_offset.x
                : 0;
            const silhouette_height_component = this.config.show_silhouette
                ? this.config.silhouette_offset.y
                : 0;
            return {
                width: silhouette_width_component + this.config.panel_width,
                height: silhouette_height_component + this.config.panel_height,
            };
        },
        /**
         * The position coordinates of the silhouette
         */
        silhouette_pos() {
            return {
                x: this.config.panel_width + this.config.silhouette_offset.x,
                y: this.dimensions.height - this.config.silhouette_height,
            };
        },
    },
    methods: {
        onPatternLoad() {
            setTimeout(() => {
                this.patternLoaded = true;
                this.$emit('pattern-loaded');
            }, 250);
        },
    },
});
