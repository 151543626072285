import { GaEventReporter } from '../Support/GaEventReporter';
const Directive = {
    inserted(el, binding) {
        const { value } = binding;
        if (!value) {
            return;
        }
        el.addEventListener('click', () => {
            GaEventReporter.report(value);
        });
    },
};
export default Directive;
