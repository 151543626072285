export default class WorkGalleryPin {
    constructor(data) {
        this.id = data.id;
        this.applicationIds = data.applicationIds;
        this.infoWindowImage = data.infoWindowImage;
        this.industryIds = data.industryIds;
        this.locations = data.locations;
        this.locationDescription = data.locationDescription;
        this.markerImage = data.markerImage;
        this.title = data.title;
    }
}
