// eslint-disable-next-line import/prefer-default-export
export const getMessageFromError = (e) => {
    const errors = e.response && e.response.data && e.response.data.errors;
    if (errors) {
        const keys = Object.keys(errors);
        const firstError = errors[keys[0]];
        if (firstError && firstError.length) {
            return firstError[0];
        }
    }
    return null;
};
