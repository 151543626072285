/* eslint-disable */
import { StringHelpers } from '../../Support/StringHelpers';
import { MONTH_LENGTHS } from './DateConstants';
/**
 * Process date input value to force validity
 */
export class DateInputInputProcessor {
    /**
     * Create a new DateInputInputProcessor instance
     */
    constructor(input, year_length = 2) {
        this.input = input;
        this.year_length = year_length;
        this.limit = 4 + year_length;
        this.is_last_key_slash = input[input.length - 1] === '/';
        this._output = input;
        this.sanitizeInput();
        this.processSlash();
        this.processMonth();
        this.processDate();
        this._formatted = this.parseFormat();
        this._value = this.parseValue();
    }
    /* eslint-disable-next-line jsdoc/require-jsdoc */
    get formatted() {
        return this._formatted;
    }
    /* eslint-disable-next-line jsdoc/require-jsdoc */
    get output() {
        return this._output;
    }
    /* eslint-disable-next-line jsdoc/require-jsdoc */
    get value() {
        return this._value;
    }
    /**
     * Facade to get a processor result
     */
    static processInput(input, year_length = 2) {
        const processor = new DateInputInputProcessor(input, year_length);
        return processor.getResult();
    }
    /**
     * Get the full processor result
     */
    getResult() {
        return {
            value: this._value,
            formatted: this._formatted,
            clean: this._output,
        };
    }
    /**
     * Determine if an entered date is valid based on the amount of days in a month
     */
    dateIsValid(month, day) {
        const max = MONTH_LENGTHS[String(month)];
        return day <= max;
    }
    /**
     * Format the output
     *
     * 1. Apply slashes after each segment.
     * 2. If last key entered is a slash, and active index allows a slash, add it
     */
    parseFormat() {
        const source = this._output;
        let formatted = source.slice(0, 2);
        if (source.length >= 3 || (this.is_last_key_slash && source.length >= 2)) {
            formatted += '/';
        }
        formatted += source.slice(2, 4);
        if (source.length >= 5 || (this.is_last_key_slash && source.length >= 4)) {
            formatted += '/';
        }
        formatted += source.slice(4);
        return formatted;
    }
    /**
     * Parse the input value
     */
    parseValue() {
        let day;
        let month;
        let year;
        if (this.output.length >= 1) {
            month = parseInt(this.output.slice(0, 2));
        }
        if (this.output.length >= 3) {
            day = parseInt(this.output.slice(2, 4));
        }
        if (this.output.length == 8 || (this.year_length === 2 && this.output.length == 6)) {
            let year_string = this.output.slice(4);
            if (this.year_length === 2 && year_string.length === 2) {
                year_string = `20${year_string}`;
            }
            year = parseInt(year_string);
        }
        return {
            day: day || false,
            month: month || false,
            year: year || false,
        };
    }
    /**
     * Complete the date segment for input.
     * If date is complete, but entered value is an invalid date (ie: 03/32),
     * prepend "0" and push last digit to year segment to create valid date (03/03/2)
     */
    processDate() {
        if (this._output.length < 4) {
            return;
        }
        const month = parseInt(this._output.slice(0, 2));
        const day = parseInt(this._output.slice(2, 4));
        if (!!month && !!day && !this.dateIsValid(month, day)) {
            this._output = StringHelpers.splice(this._output, 2, '0');
        }
    }
    /**
     * Complete the month segment for input.
     * If month is complete, but entered value is greater than 12,
     * prepend "0" to create valid month and push the last digit to date segment (14 => 01/4)
     */
    processMonth() {
        if (this._output.length < 2) {
            return;
        }
        const month = parseInt(this._output.slice(0, 2));
        if (month > 12) {
            this._output = `0${this._output}`;
        }
    }
    /**
     * Complete active segment upon slash character entry at appropriate points
     *
     * Do nothing if:
     *  1. Last character in input is not a slash.
     *  2. Slash is entered anywhere other than a place where it would complete a month or date
     *  3. The last current output character is a "0".
     *
     * Otherwise, append a "0" character to complete the segment.
     *
     * ex: user entry: "1" + "/"
     *     output: "01"
     */
    processSlash() {
        if (!this.is_last_key_slash) {
            return;
        }
        if (this._output.length !== 1 && this._output.length !== 3) {
            return;
        }
        if (this._output[this._output.length - 1] === '0') {
            return;
        }
        this._output = StringHelpers.splice(this._output, this._output.length - 1, '0');
    }
    /**
     * Limit input to proper length, and strip invalid (non-numeric) characters
     */
    sanitizeInput() {
        this._output = this._output.replace(/[^0-9]/g, '')
            .slice(0, this.limit);
    }
}
