import Vue from 'vue';
import { BasePlateSelect, EstimatedCost, HardwareSelect, PanelFinishSelect, PatternSelect, PostWeightSelect, RequestQuoteModal, SelectedFinishRendering, SelectedPatternRendering, SelectedStructureFinishRendering, SelectedSystemRendering, StructureFinishSelect, SystemSelect, } from './Components';
import SystemConfiguration from './SystemConfiguration.vue';
Vue.component('SystemSelect', SystemSelect);
Vue.component('PatternSelect', PatternSelect);
Vue.component('PanelFinishSelect', PanelFinishSelect);
Vue.component('PostWeightSelect', PostWeightSelect);
Vue.component('BasePlateSelect', BasePlateSelect);
Vue.component('HardwareSelect', HardwareSelect);
Vue.component('StructureFinishSelect', StructureFinishSelect);
Vue.component('SelectedSystemRendering', SelectedSystemRendering);
Vue.component('SelectedPatternRendering', SelectedPatternRendering);
Vue.component('SelectedFinishRendering', SelectedFinishRendering);
Vue.component('SelectedStructureFinishRendering', SelectedStructureFinishRendering);
Vue.component('RequestQuoteModal', RequestQuoteModal);
Vue.component('EstimatedCost', EstimatedCost);
Vue.component('SystemConfiguration', SystemConfiguration);
