export default class HoverIntent {
    constructor(el, method, timeout) {
        this.intent = null;
        this.timeout = timeout || HoverIntent.defaultTimeout;
        this.method = method;
        this.el = el;
        el.addEventListener('mouseover', this.onMouseOver.bind(this));
        el.addEventListener('mouseout', this.onMouseOut.bind(this));
    }
    static create(el, method, timeout) {
        return new HoverIntent(el, method, timeout);
    }
    fire(e) {
        this.el.dispatchEvent(new CustomEvent(HoverIntent.eventName, { detail: e }));
        if (this.method) {
            this.method(e);
        }
    }
    onMouseOver(e) {
        this.intent = window.setTimeout(() => {
            this.fire(e);
        }, this.timeout);
    }
    onMouseOut() {
        if (this.intent) {
            clearTimeout(this.intent);
            this.intent = null;
        }
    }
}
HoverIntent.eventName = 'hover-intent';
HoverIntent.defaultTimeout = 150;
