import Vue from 'vue';
import { FILTER_FIELDS } from '../_constants';
import { PatternsFilterableGalleryFilteringService } from '../_services/_contracts';
export default Vue.extend({
    data() {
        return {
            input_conf: {
                [PatternsFilterableGalleryFilteringService.FilterKey.OPENNESS]: {
                    min: 10,
                    max: 60,
                    step: 10,
                    unit: '%',
                },
                default: {
                    min: 1,
                    max: 10,
                    step: 1,
                },
            },
            filters: FILTER_FIELDS,
            timeout: 0,
        };
    },
    computed: {
        tooltips() {
            return this.$store.state.patterns_filterable_gallery.filter_tooltips;
        },
        state_filters() {
            return this.$store.state.patterns_filterable_gallery.filters;
        },
        free_filter: {
            get() {
                // @ts-ignore
                return this.state_filters.name;
            },
            set(value) {
                this.$store.commit('patterns_filterable_gallery/setNameFilter', value);
            },
        },
    },
    methods: {
        getValue(field) {
            // @ts-ignore
            return this.state_filters[field];
        },
        setValue(field, value) {
            if (this.timeout) {
                window.clearTimeout(this.timeout);
            }
            this.timeout = window.setTimeout(() => {
                const payload = Object.assign(Object.assign({}, value), { filter: field });
                this.$store.commit('patterns_filterable_gallery/setNumericFilterValue', payload);
                this.timeout = 0;
            }, 100);
        },
    },
});
