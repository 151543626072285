import Vue from 'vue';
export default Vue.extend({
    data() {
        return {
            timeout: 0,
        };
    },
    computed: {
        free_filter: {
            get() {
                return this.$store.state.patterns_filterable_gallery.filters.free;
            },
            set(value) {
                if (this.timeout) {
                    window.clearTimeout(this.timeout);
                }
                this.timeout = window.setTimeout(() => {
                    this.$store.commit('patterns_filterable_gallery/setNameFilter', value);
                    this.timeout = 0;
                }, 75);
            },
        },
    },
});
