import Vue from 'vue';
import './bootstrap';
import './Components';
import './Directives';
import { Accordion, AdminNotices, Autosuggest, DateInput, FlashModal, Gallery, InputMinMax, IntersectionLazyImage, Modal, Picture, ResponsiveTakeoverModal, SiteTakeover, Video, } from './Framework/Components';
import ScrollLinkDirective from './Framework/Directives/ScrollLink';
import SiteNavigation from './Framework/Layout/SiteNavigation.vue';
import DropDown from './Framework/Transitions/DropDown.vue';
import store from './store';
import './SystemConfiguration';
Vue.component('Autosuggest', Autosuggest);
Vue.component('Gallery', Gallery);
Vue.component('DropDown', DropDown);
Vue.component('InputMinMax', InputMinMax);
Vue.component('SiteTakeover', SiteTakeover);
Vue.component('Modal', Modal);
Vue.component('FlashModal', FlashModal);
Vue.component('CPicture', Picture);
Vue.component('CVideo', Video);
Vue.component('DateInput', DateInput);
Vue.component('IntersectionLazyImage', IntersectionLazyImage);
Vue.component('AdminNotices', AdminNotices);
Vue.component('Accordion', Accordion);
Vue.component('ResponsiveTakeoverModal', ResponsiveTakeoverModal);
Vue.directive('ScrollLink', ScrollLinkDirective);
const app = new Vue({
    el: '#app',
    components: {
        SiteNavigation,
    },
    store,
});
window.app = app;
