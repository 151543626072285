import { FilteringService } from '../../../Framework/Services';
import { getEnumValues } from '../../../Support/Enums';
import { PatternsFilterableGalleryFilteringService as Contracts } from './_contracts';
export default class PatternsFilterableGalleryFilteringService extends FilteringService {
    /**
     * Filter a set of patterns
     */
    static filter(patterns, filters) {
        return patterns.filter((pattern) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const key in filters) {
                if (Object.prototype.hasOwnProperty.call(filters, key)) {
                    const fieldKey = key;
                    // @ts-ignore
                    const filterValue = filters[fieldKey];
                    // @ts-ignore
                    const patternValue = pattern[fieldKey];
                    if (patternValue === 'undefined') {
                        throw Error(`pattern field ${fieldKey} not defined`);
                    }
                    const alphaKeys = getEnumValues(Contracts.AlphaFilterKey);
                    const numericKeys = getEnumValues(Contracts.NumericFilterKey);
                    if (alphaKeys.indexOf(fieldKey) !== -1) {
                        if (typeof filterValue !== 'string') {
                            throw Error(`filter value ${fieldKey} is not a string as expected by filter`);
                        }
                        if (!PatternsFilterableGalleryFilteringService
                            .patternPassesStringFilter(pattern, filterValue, fieldKey)) {
                            return false;
                        }
                    }
                    if (numericKeys.indexOf(fieldKey) !== -1) {
                        if (typeof filterValue !== 'object'
                            || typeof filterValue.min !== 'number'
                            || typeof filterValue.max !== 'number') {
                            throw Error(`filter value ${fieldKey} is not a min max object as expected by filter`);
                        }
                        if (!PatternsFilterableGalleryFilteringService
                            .patternPassesScoreFilter(pattern, filterValue, fieldKey)) {
                            return false;
                        }
                    }
                }
            }
            return true;
        });
    }
    /**
     * Whether a pattern passes a string filter by field
     */
    static patternPassesStringFilter(pattern, filterValue, field) {
        // @ts-ignore
        const patternValue = pattern[field];
        if (typeof patternValue !== 'string') {
            throw Error(`pattern field ${field} is not a string as expected by filter`);
        }
        return PatternsFilterableGalleryFilteringService
            .valuePassesStringFilter(patternValue, filterValue);
    }
    /**
     * Whether a pattern passes a score filter by field
     */
    static patternPassesScoreFilter(pattern, filterValue, field) {
        // @ts-ignore
        const patternValue = pattern[field];
        if (typeof patternValue !== 'number') {
            throw Error(`pattern field ${field} is not a number as expected by filter`);
        }
        return patternValue >= filterValue.min && patternValue <= filterValue.max;
    }
}
