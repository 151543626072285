import LibraryLoginModal from '../Components/LibraryLoginModal.vue';
import { getValueFromCookie } from '../Support/Cookies';
import { GaEventReporter } from '../Support/GaEventReporter';
export class GatedLink {
    constructor(el, binding) {
        this.ga_event = null;
        this.modal = null;
        this.track_config = null;
        this.el = el;
        this.link = el.getAttribute('href') || '';
        if (!this.link) {
            throw Error('No link provided for login modal');
        }
        const { cookie, ga_event_config, auth_url, contactLink, track_config, } = GatedLink.parseConfig(binding);
        this.auth_url = auth_url;
        this.cookie = cookie;
        this.contactLink = contactLink;
        if (ga_event_config) {
            this.ga_event = ga_event_config;
        }
        if (track_config) {
            this.track_config = track_config;
        }
        this.attach();
    }
    static create(el, binding) {
        return new GatedLink(el, binding);
    }
    static parseConfig(binding) {
        const binding_value = binding.value;
        const result = {
            cookie: '',
            auth_url: '',
        };
        if (!binding_value) {
            throw Error('Incorrectly bound Gated Link config - no binding');
        }
        if (!binding_value.cookie) {
            throw Error('Incorrectly bound Gated Link config - no cookie');
        }
        if (!binding_value.route) {
            throw Error('Incorrectly bound Gated Link config - no route');
        }
        if (binding_value.contactLink) {
            result.contactLink = binding_value.contactLink;
        }
        result.cookie = binding_value.cookie;
        result.auth_url = binding_value.route;
        const ga_event_binding = binding_value.ga_event_config;
        result.track_config = binding_value.track_config;
        if (ga_event_binding && typeof ga_event_binding === 'object') {
            result.ga_event_config = {
                category: ga_event_binding.category,
                action: ga_event_binding.action,
                label: ga_event_binding.label,
            };
        }
        return result;
    }
    getModalProps() {
        const modal_props = {
            link: this.link,
            auth_url: this.auth_url,
        };
        if (this.ga_event) {
            modal_props.ga_event_config = this.ga_event;
        }
        if (this.track_config) {
            modal_props.track_config = this.track_config;
        }
        if (this.contactLink) {
            modal_props.contactLink = this.contactLink;
        }
        return modal_props;
    }
    attach() {
        this.el.addEventListener('click', (clickEvent) => {
            if (this.isGateOpen()) {
                return this.handleGateOpen();
            }
            clickEvent.preventDefault();
            return this.handleGateClosed();
        });
    }
    buildModal() {
        const modal = new LibraryLoginModal({
            propsData: this.getModalProps(),
        });
        modal.attach();
        this.modal = modal;
        this.modal.$on('gate-completed', () => {
            this.el.dispatchEvent(new Event('gate-completed'));
        });
        return modal;
    }
    handleGateClosed() {
        if (this.ga_event) {
            GaEventReporter.report(Object.assign(Object.assign({}, this.ga_event), { label: 'Gate Accessed' }));
        }
        this.launchModal();
    }
    handleGateOpen() {
        if (this.ga_event) {
            GaEventReporter.report(this.ga_event);
        }
    }
    static isOpen(value) {
        if (value && value.cookie) {
            try {
                return !!getValueFromCookie(value.cookie, 'library', true);
            }
            catch (e) {
                return false;
            }
        }
        return false;
    }
    isGateOpen() {
        try {
            return !!getValueFromCookie(this.cookie, 'library', true);
        }
        catch (e) {
            return false;
        }
    }
    launchModal() {
        const modal = this.modal ? this.modal : this.buildModal();
        modal.open();
    }
}
const GatedLinkDirective = {
    bind(el, binding) {
        if (binding.value) {
            GatedLink.create(el, binding);
        }
    },
};
export default GatedLinkDirective;
