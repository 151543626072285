import Vue from 'vue';
import { DateInputInputProcessor } from './DateInputInputProcessor';
export default Vue.extend({
    props: {
        /**
         * Whether to use a 4-digit year instead of a 2-digit year when formatting
         */
        fourDigitYear: {
            default: false,
            type: Boolean,
        },
    },
    mounted() {
        this.$el.addEventListener('input', (e) => {
            this.handleInput(e);
        });
    },
    methods: {
        /**
         * Handle user input into form field
         * 1. process input
         * 2. write format back to input element
         * 3. report value change
         */
        handleInput(e) {
            const event_target = e.target;
            const processor_result = this.processInput(event_target.value);
            this.writeInputValue(processor_result.formatted);
            this.reportValue(processor_result);
        },
        /**
         * Process input value
         */
        processInput(input) {
            return DateInputInputProcessor.processInput(input, this.fourDigitYear ? 4 : 2);
        },
        /**
         * Report value change
         *
         * Strip trailing slashes from formatted input
         */
        reportValue(processor_result) {
            const component_value = {
                value: processor_result.value,
                formatted: processor_result.formatted.replace(/\/$/, ''),
                clean: processor_result.clean,
            };
            this.$emit('input', component_value);
        },
        /**
         * Write value to input
         */
        writeInputValue(value) {
            const input = this.$el;
            input.value = value;
        },
    },
    render() {
        const defaultSlot = this.$scopedSlots.default;
        const { handleInput, } = this;
        return defaultSlot({
            handleInput,
        });
    },
});
