import Vue from 'vue';
export default Vue.extend({
    props: {
        dialogClasses: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            show: false,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.show = true;
        });
    },
    methods: {
        close() {
            this.$emit('close');
        },
        open() {
            this.show = true;
        },
        hide() {
            this.show = false;
        },
    },
});
