import Vue from 'vue';
export default Vue.extend({
    data() {
        return {
            localValue: null,
        };
    },
    computed: {
        active_sort_filter() {
            return this.$store.getters['patterns_filterable_gallery/active_sort_filter'];
        },
        available_filters() {
            return this.$store.state.patterns_filterable_gallery.available_sort_filters;
        },
    },
    created() {
        this.localValue = this.active_sort_filter;
    },
    methods: {
        applySort() {
            const { localValue } = this;
            if (localValue
                && !(localValue.key === this.active_sort_filter.key
                    && localValue.order === this.active_sort_filter.order)) {
                this.$store.commit('patterns_filterable_gallery/setSortFilter', localValue);
            }
            this.$emit('close');
        },
    },
});
