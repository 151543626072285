import gsap from 'gsap';
import ScrollHelpers from '../Support/ScrollHelpers';
const ScrollLinkDirective = {
    bind(el, binding) {
        const offset = binding.arg === 'offset' ? parseInt(binding.value, 10) : 0;
        el.addEventListener('click', (e) => {
            const targetEl = document.getElementById((el.getAttribute('href') || '').replace('#', ''));
            if (targetEl) {
                e.preventDefault();
                const targetScroll = ScrollHelpers.getElementTopOffSet(targetEl) - offset;
                gsap.fromTo(document.scrollingElement, {
                    scrollTop: ScrollHelpers.getElementScrollTop(ScrollHelpers.getRootScrollingElement()),
                }, {
                    scrollTop: targetScroll,
                    duration: 0.25,
                    ease: 'sine.out',
                    roundProps: 'scrollTop',
                });
            }
        });
    },
};
export default ScrollLinkDirective;
