import SubstructureModal from '../Components/SubstructureModal.vue';
import { IntersectionLazyImage } from '../Framework/Components';
class SubstructureModalTrigger {
    constructor(el, binding) {
        const { image, description } = binding.value;
        this.el = el;
        this.image = image;
        this.description = description;
        this.modal = this.buildModal();
        this.attach();
    }
    static create(el, binding) {
        return new SubstructureModalTrigger(el, binding);
    }
    attach() {
        this.el.addEventListener('click', (clickEvent) => {
            clickEvent.preventDefault();
            this.launchModal();
        });
    }
    preloadImage() {
        const temp = new IntersectionLazyImage({
            propsData: {
                source: this.image,
            },
        });
        temp.loadImage();
    }
    buildModal() {
        const modal = new SubstructureModal({
            propsData: this.getModalProps(),
        });
        modal.attach();
        return modal;
    }
    getModalProps() {
        return {
            image: this.image,
            description: this.description,
        };
    }
    launchModal() {
        this.modal.open();
    }
}
const SubstructureModalTriggerDirective = {
    bind(el, binding) {
        SubstructureModalTrigger.create(el, binding);
    },
};
export default SubstructureModalTriggerDirective;
