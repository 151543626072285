import mixins from 'vue-typed-mixins';
import { IntersectionLoadsImages } from '../../../../Framework/Mixins';
// @vue/component
export default mixins(IntersectionLoadsImages).extend({
    props: {
        caseStudy: {
            type: Object,
            required: true,
        },
        debug: {
            type: Boolean,
            default: false,
        },
    },
});
