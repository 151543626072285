import mixins from 'vue-typed-mixins';
import { FilteringService } from '../../Framework/Services';
import SelectionModuleMixin from '../Mixins/SelectionModuleMixin';
const extendedVue = mixins(SelectionModuleMixin);
// @vue/component
export default extendedVue.extend({
    data() {
        return {
            filter: '',
            tooltip_delay: '{"show": 200, "hide": 0}',
        };
    },
    computed: {
        options() {
            return this.$store.state.system_configuration.options.patterns;
        },
        filteredOptions() {
            return this.options.filter((option) => FilteringService.valuePassesStringFilter(option.name, this.filter));
        },
        selection: {
            get() {
                return this.$store.state.system_configuration.selections.pattern;
            },
            set(value) {
                this.$store.commit('system_configuration/setPatternSelection', value);
            },
        },
    },
    methods: {
        selectOption(option, e) {
            const { target } = e;
            if (target instanceof HTMLButtonElement) {
                target.blur();
            }
            this.selection = option;
        },
    },
});
