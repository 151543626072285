/* eslint-disable camelcase, import/prefer-default-export */
/**
 * Get a value from a cookie using the cookie name
 */
export function getValueFromCookie(cookie_name, cookie_descriptor = '', suppress_warnings) {
    let cookie_value = '';
    if (cookie_name) {
        const pattern = `(?:(?:^|.*;\\s*)${cookie_name}\\s*\\=\\s*([^;]*).*$)|^.*$`;
        const cookieValue = document.cookie.replace(new RegExp(pattern), '$1');
        cookie_value = cookieValue.trim();
        if (cookie_value !== '') {
            return cookie_value;
        }
    }
    if (!suppress_warnings) {
        console.warn(`Unable to retrieve ${cookie_descriptor} cookie value`);
    }
    throw Error(`Unable to retrieve ${cookie_descriptor} cookie value`);
}
