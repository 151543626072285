import Vue from 'vue';
export default Vue.extend({
    props: {
        source: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        const img = this.$refs.img;
        const $el = this.$el;
        img.classList.add(...Array.from($el.classList));
    },
    methods: {
        onImageLoad() {
            this.$emit('load');
        },
    },
});
