import Vue from 'vue';
import ClickOut from '../Support/ClickOut';
import HoverIntent from '../Support/HoverIntent';
export default Vue.extend({
    props: {
        /**
         * The breakpoint at which menu begins acting in desktop mode
         */
        modeBreakpoint: {
            type: Number,
            default: 992,
        },
        subnavHover: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            /**
             * Indices indicating active submenus
             */
            active_submenu_indices: [],
            /**
             * Whether nav is acting in desktop mode
             */
            is_desktop_mode: window.innerWidth >= this.modeBreakpoint,
            /**
             * Whether the mobile menu is open
             */
            is_menu_open: false,
            transition_name: window.innerWidth >= this.modeBreakpoint ? '' : 'slide-out-north',
            force_show: window.innerWidth >= this.modeBreakpoint,
            menu_invisible: false,
        };
    },
    computed: {
        dropdownTransition() {
            return this.is_desktop_mode ? 'transition' : 'drop-down';
        },
    },
    watch: {
        /**
         * Clear active submenus on mode change
         * Close menu on mode change
         */
        is_desktop_mode(val) {
            this.menuClose();
            this.active_submenu_indices = [];
            this.transition_name = '';
            this.force_show = val;
            this.menu_invisible = true;
            this.$nextTick(() => {
                this.menu_invisible = false;
                this.transition_name = 'slide-out-north';
            });
        },
    },
    mounted() {
        if (this.subnavHover) {
            this.addHover();
        }
        window.addEventListener('resize', () => {
            this.handleResize();
        });
    },
    methods: {
        addHover() {
            const toggles = this.$el.querySelectorAll('[data-hover-target]');
            Array.from(toggles).forEach((toggle) => {
                if (toggle instanceof HTMLAnchorElement) {
                    const index = toggle.dataset.hoverTarget && parseInt(toggle.dataset.hoverTarget, 10);
                    if (typeof index === 'number') {
                        HoverIntent.create(toggle, () => {
                            if (this.is_desktop_mode) {
                                this.subMenuOpen(index);
                            }
                        }, 100);
                    }
                }
            });
        },
        /**
         * Detect mode on window resize
         */
        handleResize() {
            this.is_desktop_mode = window.innerWidth >= this.modeBreakpoint;
        },
        /**
         * Whether to show the nav menu
         */
        menuShow() {
            return this.is_menu_open || this.force_show;
        },
        /**
         * Close the menu
         */
        menuClose() {
            this.is_menu_open = false;
            if (this.$refs.menu instanceof HTMLElement) {
                this.$refs.menu.scrollTop = 0;
            }
            document.body.classList.remove('site-menu-open');
        },
        /**
         * Open the menu
         */
        menuOpen() {
            const event = document.createEvent('Event');
            event.initEvent('refresh-carousels');
            this.is_menu_open = true;
            window.scrollTo(0, 0);
            document.body.classList.add('site-menu-open');
            window.dispatchEvent(event);
        },
        /**
         * Toggle the nav menu
         */
        menuToggle() {
            return this.is_menu_open ? this.menuClose() : this.menuOpen();
        },
        onMouseLeave(index) {
            if (this.is_desktop_mode) {
                this.subMenuClose(index);
            }
        },
        /**
         * Whether to show a submenu
         */
        subMenuShow(index) {
            return this.active_submenu_indices.indexOf(index) !== -1;
        },
        /**
         * Open a submenu
         */
        subMenuOpen(index, event) {
            if (this.is_desktop_mode) {
                this.active_submenu_indices = [index];
                const target = event && event.target;
                const parentNode = target instanceof Node && target.parentNode;
                if (parentNode instanceof Node) {
                    ClickOut.create(parentNode, this.subMenuClose);
                }
                return;
            }
            this.active_submenu_indices.push(index);
        },
        /**
         * Close a submenu
         */
        subMenuClose(index) {
            const innerIndex = this.active_submenu_indices.indexOf(index);
            this.active_submenu_indices.splice(innerIndex, 1);
        },
        /**
         * Toggle a submenu
         */
        subMenuToggle(index, event) {
            return this.subMenuShow(index) ? this.subMenuClose(index) : this.subMenuOpen(index, event);
        },
    },
});
