/* eslint-disable camelcase,vue/prop-name-casing */
import axios from 'axios';
import Vue from 'vue';
import { GaEventReporter } from '../Support/GaEventReporter';
import { TrackedFileLinkReporter } from '../Support/TrackedFileLinkReporter';
export default Vue.extend({
    props: {
        link: {
            type: String,
            required: true,
        },
        contactLink: {
            type: String,
            required: false,
            default: '/contact',
        },
        ga_event_config: {
            type: Object,
            required: false,
            default: null,
        },
        track_config: {
            type: Object,
            required: false,
            default: null,
        },
        auth_url: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            error: false,
            show: false,
            authorized: false,
            submitting: false,
            formData: {
                first_name: '',
                last_name: '',
                email: '',
                zip_code: '',
                profession: '',
                firm: '',
                newsletter: false,
            },
            professions: [
                'Architect',
                'Distributor',
                'Fabricator',
                'General Contractor',
                'Installer',
                'Interior Designer',
                'Landscape Architect/Contractor',
                'Millwork',
                'Misc. Metals',
                'Owner\'s Group',
                'Purchasing Agent',
                'Other',
            ],
        };
    },
    watch: {
        formData: {
            deep: true,
            handler() {
                this.error = false;
            },
        },
    },
    methods: {
        attach() {
            this.$mount();
            document.body.appendChild(this.$el);
        },
        close() {
            this.$emit('close');
        },
        open() {
            this.show = true;
        },
        onDownloadClick() {
            const event_config = this.ga_event_config || false;
            if (event_config) {
                GaEventReporter.report(event_config);
            }
            if (this.track_config) {
                TrackedFileLinkReporter.report(this.track_config);
            }
        },
        reportGateCompleted() {
            const event_config = this.ga_event_config || false;
            if (event_config) {
                GaEventReporter.report(Object.assign(Object.assign({}, event_config), { label: 'Gate Completed' }));
            }
        },
        reportLogin() {
            this.error = false;
            this.submitting = true;
            axios.post(this.auth_url, this.formData, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
            })
                .then(() => {
                this.submitting = false;
                this.authorized = true;
                this.reportGateCompleted();
                this.$emit('gate-completed');
            })
                .catch(() => {
                this.submitting = false;
                this.error = true;
            });
        },
    },
});
