import { getEnumValues } from '../../../Support/Enums';
import { PatternsFilterableGallerySortingService as Contracts } from './_contracts';
export default class PatternsFilterableGallerySortingService {
    /**
     * Sort an array of sortables by a specified sort filter
     */
    static sort(patterns, sortFilter) {
        const newestKeys = getEnumValues(Contracts.SortFilterNewestKey);
        const numericKeys = getEnumValues(Contracts.SortFilterNumericKey);
        const alphaKeys = getEnumValues(Contracts.SortFilterAlphaKey);
        if (newestKeys.indexOf(sortFilter.key) !== -1) {
            return PatternsFilterableGallerySortingService.sortNewest(patterns);
        }
        if (numericKeys.indexOf(sortFilter.key) !== -1) {
            return PatternsFilterableGallerySortingService.sortNumeric(patterns, sortFilter);
        }
        if (alphaKeys.indexOf(sortFilter.key) !== -1) {
            return PatternsFilterableGallerySortingService.sortAlpha(patterns, sortFilter);
        }
        throw Error(`"${sortFilter.key}" sorting method not configured.`);
    }
    /**
     * Compare two sortables alphabetically
     */
    static compareAlpha(a, b) {
        return a.name.toLowerCase()
            .localeCompare(b.name.toLowerCase());
    }
    /**
     * Sort a list of sortables alphabetically
     */
    static sortAlpha(patterns, sortFilter) {
        const multiplier = sortFilter.order === 'desc' ? 1 : -1;
        return patterns.sort((a, b) => PatternsFilterableGallerySortingService.compareAlpha(a, b) * multiplier);
    }
    /**
     * Compare two sortables based on a specified numeric field value
     */
    static compareNumeric(a, b, field) {
        // @ts-ignore
        return b[field] - a[field];
    }
    /**
     * Sort a list of sortables on a numeric field
     */
    static sortNumeric(patterns, sortFilter) {
        const field = sortFilter.key;
        const multiplier = sortFilter.order === 'desc' ? 1 : -1;
        return patterns.sort((a, b) => {
            const comparison = PatternsFilterableGallerySortingService.compareNumeric(a, b, field);
            if (comparison === 0) {
                return PatternsFilterableGallerySortingService.compareAlpha(a, b);
            }
            return comparison * multiplier;
        });
    }
    /**
     * Sort a list of sortables by new first.
     *
     * List of new alphabetically, followed by list of non-new alphabetically
     */
    static sortNewest(patterns) {
        return patterns.sort((a, b) => {
            if (a.is_new === b.is_new) {
                return PatternsFilterableGallerySortingService.compareAlpha(a, b);
            }
            if (a.is_new) {
                return -1;
            }
            return 1;
        });
    }
}
