/* eslint-disable no-inner-declarations */
export var StringHelpers;
(function (StringHelpers) {
    /**
     * Convert a string to title case
     */
    function titleCase(str) {
        const mod = str.toLowerCase()
            .split(' ');
        for (let i = 0; i < mod.length; i += 1) {
            mod[i] = mod[i].charAt(0)
                .toUpperCase() + mod[i].slice(1);
        }
        return mod.join(' ');
    }
    StringHelpers.titleCase = titleCase;
    /**
     * Convert a string to title case
     */
    function capitalize(str) {
        const mod = str.toLowerCase();
        return mod.charAt(0)
            .toUpperCase() + mod.slice(1);
    }
    StringHelpers.capitalize = capitalize;
    /**
     * Convert a string in snake_case to kebab-case
     */
    function kebabCaseFromSnakeCase(str) {
        return str.replace(/_/g, '-');
    }
    StringHelpers.kebabCaseFromSnakeCase = kebabCaseFromSnakeCase;
    /**
     * Insert a new string at a position within the string
     */
    function splice(string, position, add) {
        return string.slice(0, position) + add + string.slice(position);
    }
    StringHelpers.splice = splice;
    /**
     * Escape a string that may contain regex characters
     */
    function escapeRegex(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
    StringHelpers.escapeRegex = escapeRegex;
    /**
     * Format a key into a display string
     *
     * Replace underscores with spaces
     */
    function displayFromKey(string) {
        return string.replace(/_/g, ' ');
    }
    StringHelpers.displayFromKey = displayFromKey;
})(StringHelpers || (StringHelpers = {}));
