export var PatternsFilterableGallerySortingService;
(function (PatternsFilterableGallerySortingService) {
    let SortFilterKey;
    (function (SortFilterKey) {
        SortFilterKey["ALPHA"] = "alpha";
        SortFilterKey["NEWEST"] = "newest";
        SortFilterKey["OPENNESS"] = "openness_percentage";
        SortFilterKey["SHADE"] = "shade";
        SortFilterKey["PRIVACY"] = "privacy";
        SortFilterKey["BIOMIMIC"] = "biomimicry";
        SortFilterKey["STRENGTH"] = "structural_strength";
        SortFilterKey["SMALL_HOLES"] = "small_hole_size";
        SortFilterKey["MESH"] = "mesh";
    })(SortFilterKey || (SortFilterKey = {}));
    //
    let SortFilterNumericKey;
    (function (SortFilterNumericKey) {
        SortFilterNumericKey["OPENNESS"] = "openness_percentage";
        SortFilterNumericKey["SHADE"] = "shade";
        SortFilterNumericKey["PRIVACY"] = "privacy";
        SortFilterNumericKey["BIOMIMIC"] = "biomimicry";
        SortFilterNumericKey["STRENGTH"] = "structural_strength";
        SortFilterNumericKey["SMALL_HOLES"] = "small_hole_size";
        SortFilterNumericKey["MESH"] = "mesh";
    })(SortFilterNumericKey = PatternsFilterableGallerySortingService.SortFilterNumericKey || (PatternsFilterableGallerySortingService.SortFilterNumericKey = {}));
    //
    let SortFilterAlphaKey;
    (function (SortFilterAlphaKey) {
        SortFilterAlphaKey["ALPHA"] = "alpha";
    })(SortFilterAlphaKey = PatternsFilterableGallerySortingService.SortFilterAlphaKey || (PatternsFilterableGallerySortingService.SortFilterAlphaKey = {}));
    //
    let SortFilterNewestKey;
    (function (SortFilterNewestKey) {
        SortFilterNewestKey["NEWEST"] = "newest";
    })(SortFilterNewestKey = PatternsFilterableGallerySortingService.SortFilterNewestKey || (PatternsFilterableGallerySortingService.SortFilterNewestKey = {}));
    //
    let OrderKey;
    (function (OrderKey) {
        OrderKey["ASC"] = "asc";
        OrderKey["DESC"] = "desc";
    })(OrderKey = PatternsFilterableGallerySortingService.OrderKey || (PatternsFilterableGallerySortingService.OrderKey = {}));
})(PatternsFilterableGallerySortingService || (PatternsFilterableGallerySortingService = {}));
