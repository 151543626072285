import Vue from 'vue';
export default Vue.extend({
    props: {
        caseStudy: {
            type: Object,
            required: true,
        },
        location: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showPlaceholder: true,
        };
    },
    computed: {
        alt() {
            return `Image of ${this.caseStudy.title}`;
        },
    },
    methods: {
        onImageLoad() {
            this.showPlaceholder = false;
        },
    },
});
