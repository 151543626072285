import { Loader } from '@googlemaps/js-api-loader';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import Vue from 'vue';
import WorkGalleryMapInfoWindow from '../WorkGalleryMapInfoWindow.vue';
import { MAP_STYLES, MAX_ZOOM, MIN_WINDOW_WIDTH, MIN_ZOOM, } from './Constants';
export default Vue.extend({
    props: {
        apiKey: {
            type: String,
            default: '',
        },
        caseStudies: {
            type: Array,
            required: true,
        },
        visibleCaseStudies: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            map: null,
            openInfoWindow: null,
            markers: {},
            infoWindows: {},
            bounds: null,
            allMarkers: [],
            markerCluster: null,
        };
    },
    watch: {
        visibleCaseStudies(val) {
            if (this.openInfoWindow) {
                this.openInfoWindow.close();
            }
            this.updateMarkerVisibility(val.map((i) => i.id));
        },
    },
    /**
     * Initialize map based on viewport width
     */
    created() {
        const checkMapInit = () => {
            if (window.innerWidth >= MIN_WINDOW_WIDTH) {
                this.initMap();
                window.removeEventListener('resize', checkMapInit);
            }
        };
        window.addEventListener('resize', checkMapInit);
        checkMapInit();
    },
    methods: {
        createMarker(location, caseStudy) {
            const { lat, lng } = location;
            if ((lat !== 0 && !lat) || (lng !== 0 && !lng)) {
                return null;
            }
            return new google.maps.Marker({
                position: {
                    lat,
                    lng,
                },
                map: this.map,
                title: caseStudy.title,
                icon: caseStudy.markerImage,
            });
        },
        createLocationWindow(location, caseStudy) {
            const component = new WorkGalleryMapInfoWindow({
                propsData: {
                    caseStudy,
                    location,
                },
            }).$mount();
            return new google.maps.InfoWindow({
                content: component.$el,
            });
        },
        findOrCreateInfoWindow(location, caseStudy) {
            const caseStudyId = caseStudy.id.toString();
            const locationId = location.id.toString();
            const existingWindow = this.infoWindows[caseStudyId][locationId];
            if (existingWindow) {
                return existingWindow;
            }
            const infoWindow = this.createLocationWindow(location, caseStudy);
            this.infoWindows[caseStudyId][locationId] = infoWindow;
            return infoWindow;
        },
        activateInfoWindow(location, caseStudy, marker) {
            if (this.openInfoWindow) {
                this.openInfoWindow.close();
            }
            const infoWindow = this.findOrCreateInfoWindow(location, caseStudy);
            infoWindow.open(this.map, marker);
            this.openInfoWindow = infoWindow;
        },
        createMarkers(caseStudies) {
            caseStudies.forEach((caseStudy) => {
                this.markers[caseStudy.id.toString()] = {};
                this.infoWindows[caseStudy.id.toString()] = {};
                if (!(caseStudy.locations && caseStudy.locations.length)) {
                    return;
                }
                caseStudy.locations.forEach((location) => {
                    const marker = this.createMarker(location, caseStudy);
                    if (marker) {
                        this.allMarkers.push(marker);
                        const position = marker.getPosition();
                        if (position && this.bounds) {
                            this.bounds.extend(position);
                        }
                        marker.addListener('click', () => {
                            this.activateInfoWindow(location, caseStudy, marker);
                        });
                    }
                    this.markers[caseStudy.id.toString()][location.id.toString()] = marker;
                });
            });
        },
        buildMap() {
            this.map = new google.maps.Map(this.$refs.map, {
                // center: DEFAULT_CENTER,
                // zoom: DEFAULT_ZOOM,
                styles: MAP_STYLES,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                minZoom: MIN_ZOOM,
                maxZoom: MAX_ZOOM,
            });
            this.createMarkers(this.caseStudies);
            this.setInitialBounds(this.map);
            // this.createMarkerBounds();
            // this.addClusterer();
        },
        setInitialBounds(map) {
            const dynamicBounds = this.bounds;
            if (dynamicBounds) {
                map.fitBounds(dynamicBounds);
                return;
            }
            const sw = {
                lat: 28.7157,
                lng: -123.1207,
            };
            const ne = {
                lat: 46.5,
                lng: -73.71235,
            };
            const bounds = new google.maps.LatLngBounds(sw, ne);
            map.fitBounds(bounds);
        },
        createMarkerBounds() {
            this.bounds = new google.maps.LatLngBounds();
        },
        addClusterer() {
            if (this.map) {
                const path = '/images/work';
                this.markerCluster = new MarkerClusterer(this.map, this.allMarkers, {
                    imagePath: `${path}/m`,
                    ignoreHidden: true,
                    gridSize: 30,
                });
            }
        },
        initMap() {
            const loader = new Loader({
                apiKey: this.apiKey,
                version: 'weekly',
            });
            loader.load().then(() => {
                this.buildMap();
            });
        },
        showCaseStudyMarkers(caseStudyId) {
            const caseStudyMarkers = this.markers[caseStudyId];
            Object.keys(caseStudyMarkers).forEach((key) => {
                const marker = caseStudyMarkers[key];
                if (marker) {
                    marker.setMap(this.map);
                }
            });
        },
        hideCaseStudyMarkers(caseStudyId) {
            const caseStudyMarkers = this.markers[caseStudyId];
            Object.keys(caseStudyMarkers).forEach((key) => {
                const marker = caseStudyMarkers[key];
                if (marker) {
                    marker.setMap(null);
                }
            });
        },
        updateMarkerVisibility(visible_ids) {
            Object.keys(this.markers).forEach((id) => {
                if (visible_ids.indexOf(parseInt(id, 10)) !== -1) {
                    return this.showCaseStudyMarkers(id);
                }
                return this.hideCaseStudyMarkers(id);
            });
        },
    },
});
