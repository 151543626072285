import Vue from 'vue';
/**
 * NOTE: implementing as restricted.  Future date extend to unrestricted
 */
export default Vue.extend({
    props: {
        /**
         * Additional input attributes to bind
         */
        inputAttrs: {
            type: Object,
            default: () => ({}),
        },
        /**
         * The name attribute of the input to which values should be bound
         */
        inputName: {
            required: true,
            type: String,
        },
        /**
         * Whether the component allows selection of multiple options
         */
        multiple: {
            type: Boolean,
            default: false,
        },
        /**
         * The options available for suggestion
         */
        options: {
            type: Array,
            required: true,
        },
        /**
         * Input placeholder
         */
        placeholder: {
            type: String,
            default: '',
        },
        /**
         * The initial value of the input
         */
        value: {
            type: [Number, String, Array],
            required: true,
        },
    },
    /**
     * Reactive data
     */
    data() {
        return {
            /**
             * The index of the actively-selected suggestion
             */
            active_suggestion_index: -1,
            /**
             * Whether to refocus input on blur
             */
            focusBlur: false,
            /**
             * The list of selected options (applicable to multi)
             */
            selectedOptions: [],
            /**
             * Active list of suggestions
             */
            suggestions: [],
            /**
             * Binding for user input field
             */
            textFieldValue: '',
        };
    },
    computed: {
        /**
         * The currently active suggestion
         */
        active_suggestion() {
            if (this.suggestions.length) {
                return this.suggestions[this.active_suggestion_index];
            }
            return null;
        },
        /**
         * Input placeholder binding.  Remove once an option is selected
         */
        inputPlaceholder() {
            if (this.selectedOptions.length) {
                return '';
            }
            return this.placeholder;
        },
        /**
         * The binding with parent v-model directive
         */
        modelBinding: {
            /**
             * Get the modelBinding
             */
            get() {
                return this.value;
            },
            /**
             * Set the modelBinding
             */
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    watch: {
        /**
         * When active suggestion changes, ensure it appears in view
         */
        active_suggestion_index(index) {
            if (index >= 0) {
                this.$nextTick(() => {
                    this.scrollSuggestionIntoView();
                });
            }
        },
    },
    methods: {
        /**
         * Clear the suggestions list
         */
        clearSuggestions() {
            this.suggestions = [];
        },
        /**
         * Get the index of the next available selection
         */
        getNextSuggestionIndex() {
            const start = this.active_suggestion_index + 1;
            for (let i = start; i < this.suggestions.length; i += 1) {
                const suggestion = this.suggestions[i];
                if (!this.optionSelected(suggestion)) {
                    return i;
                }
            }
            return null;
        },
        /**
         * Get the index of the previous available selection
         */
        getPreviousSuggestionIndex() {
            const start = this.active_suggestion_index - 1;
            for (let i = start; i >= 0; i -= 1) {
                const suggestion = this.suggestions[i];
                if (!this.optionSelected(suggestion)) {
                    return i;
                }
            }
            return null;
        },
        /**
         * Get the suggestions given the current user input
         */
        getSuggestions() {
            if (!this.options) {
                return [];
            }
            return this.options.filter((option) => {
                if (!this.textFieldValue) {
                    return false;
                }
                return option.label
                    .toLowerCase()
                    .indexOf(this.textFieldValue.toLowerCase()) !== -1;
            });
        },
        /**
         * Handle click/touch event on a suggestion
         */
        onSuggestionClick(option) {
            if (this.multiple) {
                this.focusBlur = true;
            }
            this.selectSuggestion(option);
        },
        /**
         * Handle input event on user input
         */
        onUserInput() {
            this.suggestions = this.getSuggestions();
            this.active_suggestion_index = -1;
            const nextIndex = this.getNextSuggestionIndex();
            if (nextIndex !== null) {
                this.$nextTick(() => {
                    this.active_suggestion_index = nextIndex;
                });
            }
        },
        /**
         * Handle blur on user input
         */
        onUserInputBlur() {
            this.clearSuggestions();
            this.textFieldValue = '';
            if (this.focusBlur) {
                const { input } = this.$refs;
                if (input instanceof HTMLInputElement) {
                    input.focus();
                }
                this.focusBlur = false;
            }
        },
        /**
         * Handle enter key press in user input
         */
        onUserInputEnter() {
            this.selectActiveSuggestion();
        },
        /**
         * Handle down key press in user input
         */
        onUserInputKeydown() {
            const nextIndex = this.getNextSuggestionIndex();
            if (nextIndex !== null) {
                this.active_suggestion_index = nextIndex;
            }
        },
        /**
         * Handle tab key press in user input
         */
        onUserInputTab(event) {
            if (this.textFieldValue && this.suggestions.length) {
                event.preventDefault();
            }
            this.selectActiveSuggestion();
        },
        /**
         * Handle up key press in user input
         */
        onUserInputKeyup() {
            const previousIndex = this.getPreviousSuggestionIndex();
            if (previousIndex !== null) {
                this.active_suggestion_index = previousIndex;
            }
        },
        /**
         * Whether an option is selected
         */
        optionSelected(option) {
            return this.selectedOptions.indexOf(option) !== -1;
        },
        /**
         * Remove a selected option
         */
        removeSelectedOption(option) {
            const selectedOptionsIndex = this.selectedOptions.indexOf(option);
            const modelBindingIndex = this.modelBinding.indexOf(option.value);
            if (selectedOptionsIndex !== -1) {
                this.selectedOptions.splice(selectedOptionsIndex, 1);
            }
            if (modelBindingIndex !== -1) {
                this.modelBinding.splice(modelBindingIndex, 1);
            }
        },
        /**
         * Perform necessary scrolling to ensure the active suggestion is visible
         */
        scrollSuggestionIntoView() {
            const { suggestScrollContainer } = this.$refs;
            if (suggestScrollContainer instanceof HTMLElement) {
                const element = suggestScrollContainer.querySelector('.active');
                if (element instanceof HTMLElement) {
                    suggestScrollContainer.scrollTop = element.offsetTop;
                }
            }
        },
        /**
         * Select the active suggestion
         */
        selectActiveSuggestion() {
            if (this.active_suggestion) {
                this.selectSuggestion(this.active_suggestion);
            }
        },
        /**
         * Select a suggestion
         */
        selectSuggestion(option) {
            if (this.optionSelected(option)) {
                return;
            }
            this.clearSuggestions();
            if (this.multiple) {
                this.selectedOptions.push(option);
                this.textFieldValue = '';
                this.modelBinding.push(option.value);
                return;
            }
            this.textFieldValue = option.label;
            this.modelBinding = option.value;
        },
        /**
         * HTML string for a suggestion.  Embolden the query string
         */
        suggestionHtml(option) {
            const regEx = new RegExp(this.textFieldValue, 'ig');
            return option.label.replace(regEx, '<strong>$&</strong>');
        },
    },
});
