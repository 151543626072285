import Vue from 'vue';
import { SystemSelect } from './Components';
import { SystemConfigurationState } from './store/SystemConfigurationState';
export default Vue.extend({
    components: {
        SystemSelect,
    },
    props: {
        config: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            continueAttempt: false,
            flashContent: null,
            disableButtons: false,
        };
    },
    computed: {
        validationErrors() {
            if (this.continueAttempt) {
                return this.$store.getters['system_configuration/validationErrors'];
            }
            return [];
        },
    },
    created() {
        if (!this.$store.state.system_configuration) {
            this.$store.registerModule('system_configuration', SystemConfigurationState);
        }
        this.$store.dispatch('system_configuration/init', this.config);
    },
    methods: {
        closeFlash() {
            this.flashContent = null;
        },
        downloadSpec() {
            this.continueAttempt = true;
            if (this.validationErrors.length) {
                return;
            }
            this.disableButtons = true;
            this.$store.dispatch('system_configuration/downloadSpec').then(() => {
                this.disableButtons = false;
            }).catch((message) => {
                this.disableButtons = false;
                this.$set(this, 'flashContent', {
                    title: 'Uh oh...',
                    message,
                });
            });
        },
        RequestQuote() {
            this.continueAttempt = true;
            if (this.validationErrors.length) {
                return;
            }
            this.$store.dispatch('system_configuration/startRequestQuote');
        },
    },
    render() {
        const defaultSlot = this.$scopedSlots.default;
        const { downloadSpec, RequestQuote, config, validationErrors, flashContent, closeFlash, disableButtons, } = this;
        return defaultSlot({
            downloadSpec,
            RequestQuote,
            config,
            validationErrors,
            flashContent,
            closeFlash,
            disableButtons,
        });
    },
});
