import gsap from 'gsap';
import Vue from 'vue';
export default Vue.extend({
    data() {
        return {
            results: 0,
        };
    },
    computed: {
        result_count() {
            return this.$store.getters['patterns_filterable_gallery/filtered_patterns'].length;
        },
    },
    watch: {
        result_count(amount) {
            gsap.to(this.$data, {
                duration: 0.25,
                results: amount,
                roundProps: 'results',
            });
        },
    },
    created() {
        this.results = this.result_count;
    },
});
