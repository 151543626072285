import Vue from 'vue';
export default Vue.extend({
    data() {
        return {
            tooltip_delay: '{"show": 200, "hide": 0}',
        };
    },
    computed: {
        selection() {
            return null;
        },
        selectionName() {
            return this.selection ? this.selection.name : 'None';
        },
    },
    methods: {
        isActive(option) {
            return option === this.selection;
        },
    },
});
