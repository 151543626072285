import { DEFAULT_FILTERS, DEFAULT_SORT_KEY, DEFAULT_SORT_ORDER, SORT_FILTERS, } from '../_constants';
import { PatternsFilterableGalleryFilteringService, PatternsFilterableGallerySortingService } from '../_services';
export class State {
    constructor() {
        this.cached_filters = Object.assign({}, DEFAULT_FILTERS);
        this.filters = Object.assign({}, DEFAULT_FILTERS);
        this.filter_tooltips = {};
        this.patterns = [];
        this.available_sort_filters = SORT_FILTERS;
        this.active_sort_filter = null;
    }
}
const actions = {
    init(context, payload) {
        return new Promise((resolve) => {
            context.commit('setPatterns', payload.patterns);
            context.commit('setFilterTooltips', payload.tooltips);
            resolve();
        });
    },
};
const getters = {
    active_sort_filter(state, getters) {
        return state.active_sort_filter || getters.default_sort_filter;
    },
    default_sort_filter(state) {
        const configured = state.available_sort_filters.find((item) => item.key === DEFAULT_SORT_KEY && item.order === DEFAULT_SORT_ORDER);
        return configured || state.available_sort_filters[0];
    },
    filtered_patterns(state) {
        return PatternsFilterableGalleryFilteringService.filter(state.patterns, state.filters);
    },
    filters_set(state) {
        // eslint-disable-next-line no-restricted-syntax
        for (const i in state.filters) {
            if (Object.prototype.hasOwnProperty.call(state.filters, i)
                && Object.prototype.hasOwnProperty.call(DEFAULT_FILTERS, i)) {
                // @ts-ignore
                const filter = state.filters[i];
                // @ts-ignore
                const default_filter = DEFAULT_FILTERS[i];
                if (typeof filter === 'object') {
                    if (filter.min !== default_filter.min || filter.max !== default_filter.max) {
                        return true;
                    }
                }
                if (typeof filter === 'string' && filter !== default_filter) {
                    return true;
                }
            }
        }
        return false;
    },
    display_patterns(state, getters) {
        const patterns_to_sort = getters.filtered_patterns;
        return PatternsFilterableGallerySortingService.sort(patterns_to_sort, getters.active_sort_filter);
    },
};
const mutations = {
    cacheFilters(state) {
        state.cached_filters = Object.assign({}, state.filters);
    },
    resetFilters(state) {
        state.filters = Object.assign({}, DEFAULT_FILTERS);
    },
    restoreCachedFilters(state) {
        const object_keys = [
            'openness_percentage',
            'shade',
            'privacy',
            'biomimicry',
            'structural_strength',
            'small_hole_size',
            'mesh',
        ];
        const string_keys = ['name'];
        for (let i = 0; i < object_keys.length; i++) {
            const key = object_keys[i];
            // @ts-ignore
            state.filters[key] = Object.assign({}, state.cached_filters[key]);
        }
        for (let i = 0; i < string_keys.length; i++) {
            const key = object_keys[i];
            // @ts-ignore
            state.filters[key] = state.cached_filters[key];
        }
    },
    setFilterTooltips(state, payload) {
        state.filter_tooltips = payload;
    },
    setPatterns(state, value) {
        state.patterns = value;
    },
    setSortFilter(state, payload) {
        state.active_sort_filter = payload;
    },
    setNumericFilterValue(state, payload) {
        // @ts-ignore
        state.filters[payload.filter] = {
            min: payload.min,
            max: payload.max,
        };
    },
    setNameFilter(state, payload) {
        state.filters.name = payload;
    },
};
export const PatternsFilterableGalleryState = {
    namespaced: true,
    state: new State(),
    actions,
    getters,
    mutations,
};
