import Vue from 'vue';
import ScrollHelpers from '../../Support/ScrollHelpers';
import LibraryResult from './LibraryResult.vue';
export default Vue.extend({
    components: {
        LibraryResult,
    },
    props: {
        primaryFilters: {
            type: Array,
            required: true,
        },
        secondaryFilters: {
            type: Array,
            required: true,
        },
        files: {
            type: Array,
            required: true,
        },
        typeFilters: {
            type: Array,
            required: true,
        },
        gateActive: {
            type: Boolean,
            required: true,
        },
    },
    /**
     * Reactive data
     */
    data() {
        return {
            activePrimaryFilter: '',
            activeSecondaryFilters: [],
            activeTypeFilters: [],
            activeFreeFilter: '',
            isGateActive: this.gateActive,
            filter_takeover_active: false,
            cachedPrimaryFilter: '',
            cachedSecondaryFilters: [],
            cachedTypeFilters: [],
            showFooter: false,
        };
    },
    computed: {
        /**
         * Whether any filters are active
         */
        filtersPresent() {
            if (this.activeFreeFilter !== '') {
                return true;
            }
            if (this.activeTypeFilters.length > 0) {
                return true;
            }
            if (this.activeSecondaryFilters.length > 0) {
                return true;
            }
            if (this.activePrimaryFilter !== this.primaryFilters[0].value) {
                return true;
            }
            return false;
        },
        /**
         * The options for primary filtering
         */
        primaryFilterOptions() {
            return this.primaryFilters.map((pf) => ({
                label: pf.label,
                value: pf.value,
            }));
        },
        /**
         * The options for secondary filtering
         *
         * Only secondary filters associated with the active primary filter
         */
        secondaryFilterOptions() {
            return this.secondaryFilters
                .reduce((carry, filterConfig) => {
                if (filterConfig.primaryFilters.includes(this.activePrimaryFilter)) {
                    carry.push({
                        label: filterConfig.label,
                        value: filterConfig.value,
                    });
                }
                return carry;
            }, []);
        },
        /**
         * The options for type filtering
         *
         * Only type filters associated with the active primary filter
         */
        typeFilterOptions() {
            return this.typeFilters
                .reduce((carry, filterConfig) => {
                if (filterConfig.primaryFilters.includes(this.activePrimaryFilter)) {
                    carry.push({
                        label: filterConfig.label,
                        value: filterConfig.value,
                    });
                }
                return carry;
            }, []);
        },
        /**
         * The amount of results
         */
        resultsCount() {
            return this.visibleFiles.length;
        },
        /**
         * The visible file results
         */
        visibleFiles() {
            return this.files.filter((f) => {
                const { activePrimaryFilter, activeSecondaryFilters, activeTypeFilters, activeFreeFilter, } = this;
                if (!f.primaryFilters.includes(activePrimaryFilter)) {
                    return false;
                }
                if (activeSecondaryFilters.length) {
                    let matchFound = false;
                    for (let i = 0; i < activeSecondaryFilters.length; i += 1) {
                        const secondaryFilter = activeSecondaryFilters[i];
                        if (f.secondaryFilters.includes(secondaryFilter)) {
                            matchFound = true;
                            break;
                        }
                    }
                    if (!matchFound) {
                        return false;
                    }
                }
                if (activeTypeFilters.length) {
                    let typeMatch = false;
                    for (let i = 0; i < f.typeIds.length; i += 1) {
                        const typeId = f.typeIds[i];
                        if (activeTypeFilters.includes(typeId)) {
                            typeMatch = true;
                            break;
                        }
                    }
                    if (!typeMatch) {
                        return false;
                    }
                }
                if (activeFreeFilter) {
                    if (!f.name.toLowerCase().includes(activeFreeFilter.toLowerCase())) {
                        return false;
                    }
                }
                return true;
            }).map((f) => (Object.assign(Object.assign({}, f), { nameHtml: this.searchTermed(f.name, this.activeFreeFilter) })));
        },
    },
    watch: {
        /**
         * Remove active secondary filters that are no longer applicable
         * when available secondary filters change
         */
        secondaryFilterOptions(val) {
            const vals = val.map((f) => f.value);
            this.activeSecondaryFilters = this.activeSecondaryFilters
                .slice()
                .filter((f) => vals.includes(f));
        },
        /**
         * Remove active type filters that are no longer applicable when available type filters change
         */
        typeFilterOptions(val) {
            const vals = val.map((f) => f.value);
            this.activeTypeFilters = this.activeTypeFilters
                .slice()
                .filter((f) => vals.includes(f));
        },
    },
    /**
     * Handle component mounted event
     */
    mounted() {
        this.attachFooterObserver();
    },
    /**
     * Handle component created event
     */
    created() {
        this.clearFilters();
    },
    methods: {
        /**
         * Attach observer to hide/show footer on mobile
         */
        attachFooterObserver() {
            if (typeof window.IntersectionObserver === 'undefined') {
                this.showFooter = true;
                return;
            }
            const observerOptions = {
            // rootMargin: '-300px',
            };
            const observerCallback = (entries) => {
                entries.forEach((entry) => {
                    this.showFooter = !entry.isIntersecting;
                });
            };
            const imageObserver = new IntersectionObserver(observerCallback, observerOptions);
            imageObserver.observe(this.$refs.header);
        },
        /**
         * Handle back to top element click
         */
        backToTop() {
            const target = this.$el instanceof HTMLElement ? this.$el.offsetTop : 0;
            ScrollHelpers.smoothScrollTo(target);
        },
        /**
         * Handle intent to open filters takeover
         */
        filtersTakeoverOpen() {
            this.cacheFilters();
            this.filter_takeover_active = true;
        },
        /**
         * Handle intent to close filters takeover
         */
        filtersTakeoverClose(apply) {
            if (!apply) {
                this.restoreCachedFilters();
                this.filter_takeover_active = false;
            }
            this.filter_takeover_active = false;
        },
        /**
         * Cache current filters
         */
        cacheFilters() {
            this.cachedPrimaryFilter = this.activePrimaryFilter;
            this.cachedSecondaryFilters = this.activeSecondaryFilters.slice();
            this.cachedTypeFilters = this.activeTypeFilters.slice();
        },
        /**
         * Restore filters from cache
         */
        restoreCachedFilters() {
            this.activePrimaryFilter = this.cachedPrimaryFilter;
            this.activeSecondaryFilters = this.cachedSecondaryFilters.slice();
            this.activeTypeFilters = this.cachedTypeFilters.slice();
        },
        /**
         * Clear all active filters
         */
        clearFilters() {
            this.activeFreeFilter = '';
            this.activeTypeFilters = [];
            this.activeSecondaryFilters = [];
            this.activePrimaryFilter = this.primaryFilters[0].value;
        },
        /**
         * Respond to result item gate-completed event
         */
        onGateCompleted() {
            this.isGateActive = false;
        },
        /**
         * Take a source string, and wrap internal matches against a search term with <mark> tags
         *
         * Limitations - When overlapping matches found, only first is highlighted
         *  ex: source = "Waikiki", searchTerm ="iki"
         *    Technically, there are two matches (2-4 and 4-6), but only 2-4 will be marked
         */
        searchTermed(source, searchTerm) {
            // If search term is an empty string, return the source
            if (!searchTerm) {
                return source;
            }
            // Get case-insensitive global matches
            const matches = source.match(new RegExp(searchTerm, 'gi'));
            // If no matches, return source
            if (!matches) {
                return source;
            }
            // Result will be built on iterations
            let result = '';
            // Working string chopped up over course of iterations, used to determine replacement indices
            let workingString = source;
            // Iterate over matches to build result
            matches.forEach((match) => {
                // get index of match within working string
                const matchIndex = workingString.indexOf(match);
                // get text in working string leading up to match
                const lead = workingString.slice(0, matchIndex);
                // add leading text and marked match to result string
                result += `${lead}<mark>${match}</mark>`;
                // remove processed text (leading text and match) from working string
                workingString = workingString.slice(lead.length + match.length);
            });
            // add trailing working string text after final match
            result += workingString;
            return result;
        },
    },
});
