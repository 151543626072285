import Vue from 'vue';
import gsap from 'gsap';
export default Vue.extend({
    data() {
        return {
            is_expanded: false,
            is_transitioning: false,
            content_height: 0,
        };
    },
    computed: {
        content_style() {
            if (this.content_height !== null) {
                return `height:${this.content_height}px;`;
            }
            return null;
        },
    },
    methods: {
        animate(to) {
            return new Promise((resolve) => {
                gsap.to(this.$data, {
                    duration: 0.25,
                    content_height: to,
                    ease: 'sine.out',
                    roundProps: 'content_height',
                }).then(() => {
                    resolve();
                });
            });
        },
        toggle() {
            this.is_expanded = !this.is_expanded;
        },
        beforeEnter() {
            this.content_height = 0;
        },
        enter(el, done) {
            this.animate(el.offsetHeight).then(done);
        },
        afterEnter() {
            this.content_height = null;
        },
        beforeLeave(el) {
            this.content_height = el.offsetHeight;
        },
        leave(el, done) {
            this.animate(0).then(done);
        },
        afterLeave() {
            this.content_height = 0;
        },
    },
});
