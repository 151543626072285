import Vue from 'vue';
export default Vue.extend({
    props: {
        notices: {
            type: Array,
            required: false,
            default: [],
        },
    },
});
