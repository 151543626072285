// eslint-disable-next-line import/prefer-default-export
export class FilteringService {
    /**
     * Whether a value passes a string filter
     */
    static valuePassesStringFilter(value, filterValue) {
        return !!value.trim()
            .toLowerCase()
            .match(filterValue.trim()
            .toLowerCase());
    }
}
