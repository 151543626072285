/**
 * Helpers for calculating element positions and scrolling to elements;
 */
export default class ScrollHelpers {
    /**
     * Get the distance between the top of the element and the top of the document
     */
    static getElementTopOffSet(el) {
        let topOffset = 0;
        let element = el;
        while (element) {
            topOffset += element.offsetTop;
            element = element.offsetParent;
        }
        return topOffset;
    }
    /**
     * Scroll to a vertical offset position
     */
    static scrollToOffset(offset) {
        const scrollingElement = this.getRootScrollingElement();
        scrollingElement.scrollTop = offset;
    }
    /**
     * Get an element's height
     */
    static getElementHeight(el) {
        return el.offsetHeight;
    }
    /**
     * Get an element'scrolltop within its parent
     */
    static getElementScrollTop(element) {
        return element.scrollTop;
    }
    /**
     * Get the root scrolling element for a document
     */
    static getRootScrollingElement() {
        return document.scrollingElement || document.documentElement || document.getElementsByTagName('body')[0];
    }
    /**
     * Get the document top scroll position
     */
    static getCurrentScroll() {
        return ScrollHelpers.getRootScrollingElement().scrollTop;
    }
    /**
     * Reset the document scroll position to the top
     */
    static resetScroll() {
        ScrollHelpers.scrollTo(0);
    }
    /**
     * Set the document scroll position
     */
    static scrollTo(position) {
        ScrollHelpers.getRootScrollingElement().scrollTop = position;
    }
    /**
     * Smooth scroll to a position
     */
    static smoothScrollTo(top, left = 0) {
        ScrollHelpers.getRootScrollingElement().scrollTo({
            top,
            left,
            behavior: 'smooth',
        });
    }
}
