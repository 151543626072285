export class SortingService {
    static compareAlpha(a, b, field) {
        return a[field].toLowerCase()
            .localeCompare(b[field].toLowerCase());
    }
    static sortAlpha(resources, field, direction = 'asc') {
        const multiplier = direction === 'asc' ? 1 : -1;
        return resources.sort((a, b) => SortingService.compareAlpha(a, b, field) * multiplier);
    }
    static compareNumeric(a, b, field) {
        return a[field] - b[field];
    }
    static sortNumeric(resources, field, direction = 'asc') {
        const multiplier = direction === 'asc' ? 1 : -1;
        return resources.sort((a, b) => SortingService.compareNumeric(a, b, field) * multiplier);
    }
}
export default SortingService;
