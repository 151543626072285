import Swiper, { Autoplay, EffectFade, Keyboard, Lazy, Mousewheel, Navigation, Pagination, } from 'swiper';
import Vue from 'vue';
import { elementInViewport } from '../Support/SupportFns';
const DEFAULT_AUTOPLAY_DELAY = 2000;
export default Vue.extend({
    props: {
        swiperProps: {
            type: Object,
            default() {
                return {};
            },
        },
        pagination: {
            type: Boolean,
            default: false,
        },
        navigation: {
            type: Boolean,
            default: false,
        },
        carouselClass: {
            type: String,
            default: null,
        },
        updateAfterLazy: {
            type: Boolean,
            default: false,
        },
        updateAfterWindowLoad: {
            type: Boolean,
            default: false,
        },
        rebuildOnWindowResize: {
            type: Boolean,
            default: false,
        },
        viewportAutoplay: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            carousel: null,
            slideCount: 0,
        };
    },
    computed: {
        render_pagination() {
            return this.pagination && !this.$slots.default;
        },
    },
    mounted() {
        const slideWrap = this.$refs['slide-wrap'];
        if (slideWrap instanceof HTMLElement) {
            this.slideCount = slideWrap.childNodes.length;
        }
        Swiper.use([Mousewheel, Keyboard, EffectFade, Lazy, Pagination, Navigation, Autoplay]);
        const defaultProps = {
            threshold: 10,
        };
        if (this.pagination) {
            const paginationRef = this.$refs.pagination;
            let el = '.swiper-pagination';
            if (paginationRef && paginationRef instanceof HTMLElement) {
                el = paginationRef;
            }
            defaultProps.pagination = {
                el,
                type: 'bullets',
                clickable: true,
            };
        }
        if (this.navigation) {
            defaultProps.navigation = {
                prevEl: this.$refs.prev ? this.$refs.prev : '.swiper-button-prev',
                nextEl: this.$refs.next ? this.$refs.next : '.swiper-button-next',
            };
        }
        const compiledOptions = Object.assign(Object.assign({}, defaultProps), this.swiperProps);
        const isDynamicAutoplay = compiledOptions.autoplay && this.viewportAutoplay;
        if (compiledOptions.autoplay && !compiledOptions.autoplay.delay) {
            compiledOptions.autoplay = {
                delay: DEFAULT_AUTOPLAY_DELAY,
            };
        }
        this.carousel = new Swiper(this.$refs.gallery, compiledOptions);
        if (isDynamicAutoplay) {
            /**
             * If dynamic autoplay is enabled, stop autoplay if element is out of viewport
             */
            if (!elementInViewport(this.$refs.gallery)) {
                this.carousel.autoplay.stop();
            }
            this.addObservers();
        }
        window.addEventListener('refresh-carousels', () => {
            if (this.carousel) {
                this.carousel.update();
            }
        });
        if (this.updateAfterLazy) {
            this.carousel.on('lazyImageReady', () => {
                this.refreshCarousel();
                window.setTimeout(() => {
                    this.refreshCarousel();
                }, 500);
            });
        }
        if (this.updateAfterWindowLoad) {
            window.addEventListener('load', () => {
                this.refreshCarousel();
            });
        }
        if (this.rebuildOnWindowResize) {
            let resizeTimeout = null;
            let windowSize = window.innerWidth;
            window.addEventListener('resize', () => {
                if (resizeTimeout) {
                    window.clearTimeout(resizeTimeout);
                }
                resizeTimeout = window.setTimeout(() => {
                    if (this.carousel && window.innerWidth !== windowSize) {
                        this.carousel.destroy();
                        this.carousel = new Swiper(this.$refs.gallery, compiledOptions);
                        windowSize = window.innerWidth;
                        this.refreshCarousel();
                    }
                    resizeTimeout = null;
                }, 150);
            });
        }
    },
    methods: {
        refreshCarousel() {
            window.setTimeout(() => {
                if (this.carousel) {
                    this.carousel.update();
                }
            }, 100);
        },
        addObservers() {
            let scrollTimeout = null;
            const galleryEl = this.$refs.gallery;
            let isInViewport = elementInViewport(galleryEl);
            const gallery = this.carousel;
            const scrollListener = () => {
                if (scrollTimeout) {
                    clearTimeout(scrollTimeout);
                }
                scrollTimeout = window.setTimeout(() => {
                    const wasInViewport = isInViewport;
                    isInViewport = elementInViewport(galleryEl);
                    const entered = !wasInViewport && isInViewport;
                    const left = wasInViewport && !isInViewport;
                    // Gallery paused due to interaction, remove scroll listener
                    if (wasInViewport && !gallery.autoplay.running) {
                        window.removeEventListener('scroll', scrollListener);
                        return;
                    }
                    // Restart paused gallery on reenter
                    if (entered && !gallery.autoplay.running) {
                        gallery.autoplay.start();
                        return;
                    }
                    // Stop running gallery on exit
                    if (left && gallery.autoplay.running) {
                        gallery.autoplay.stop();
                    }
                    scrollTimeout = null;
                }, 150);
            };
            window.addEventListener('scroll', scrollListener);
        },
    },
});
