import Vue from 'vue';
import { FilteringService } from '../../Framework/Services';
import NewsTile from './NewsTile.vue';
const DEFAULT_DISPLAY_COUNT = 9;
const DISPLAY_COUNT_INCREMENT = 3;
export default Vue.extend({
    components: {
        NewsTile,
    },
    props: {
        posts: {
            type: Array,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
        showAll: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formData: {
                keyword: '',
                category_id: null,
            },
            displayCount: DEFAULT_DISPLAY_COUNT,
        };
    },
    computed: {
        showLoadMore() {
            return this.displayCount < this.filtered_posts.length;
        },
        visible_posts() {
            return this.filtered_posts.slice(0, this.displayCount);
        },
        filtered_posts() {
            return this.posts.filter((post) => {
                const activeCategoryId = this.formData.category_id;
                if (activeCategoryId && activeCategoryId > 0) {
                    if (post.category_id !== activeCategoryId) {
                        return false;
                    }
                }
                if (this.formData.keyword) {
                    return FilteringService.valuePassesStringFilter(post.title, this.formData.keyword)
                        || FilteringService.valuePassesStringFilter(post.excerpt, this.formData.keyword);
                }
                return true;
            });
        },
    },
    watch: {
        formData: {
            deep: true,
            handler() {
                this.displayCount = DEFAULT_DISPLAY_COUNT;
            },
        },
    },
    created() {
        if (this.showAll) {
            this.displayCount = this.posts.length;
        }
    },
    methods: {
        showMore() {
            this.displayCount += DISPLAY_COUNT_INCREMENT;
        },
    },
});
