import { PatternsFilterableGallerySortingService } from '../_services/_contracts';
export const SORT_FILTERS = [];
const FILTER_CONFIG = [
    {
        key: PatternsFilterableGallerySortingService.SortFilterNewestKey.NEWEST,
        label: 'Newest First',
    },
    {
        key: PatternsFilterableGallerySortingService.SortFilterAlphaKey.ALPHA,
        label: 'Alphabetical',
    },
    {
        label: 'Openness Percentage',
        key: PatternsFilterableGallerySortingService.SortFilterNumericKey.OPENNESS,
    },
    {
        label: 'Shade Score',
        key: PatternsFilterableGallerySortingService.SortFilterNumericKey.SHADE,
    },
    {
        label: 'Privacy Score',
        key: PatternsFilterableGallerySortingService.SortFilterNumericKey.PRIVACY,
    },
    {
        label: 'Biomimicry Score',
        key: PatternsFilterableGallerySortingService.SortFilterNumericKey.BIOMIMIC,
    },
    {
        label: 'Strength Score',
        key: PatternsFilterableGallerySortingService.SortFilterNumericKey.STRENGTH,
    },
    {
        label: 'Small Hole Size',
        key: PatternsFilterableGallerySortingService.SortFilterNumericKey.SMALL_HOLES,
    },
    {
        label: 'MESH Score',
        key: PatternsFilterableGallerySortingService.SortFilterNumericKey.MESH,
    },
];
const ASC_DESC_FILTER_KEYS = [
    PatternsFilterableGallerySortingService.SortFilterNumericKey.OPENNESS,
    PatternsFilterableGallerySortingService.SortFilterAlphaKey.ALPHA,
];
const FILTER_ORDER = [
    PatternsFilterableGallerySortingService.SortFilterAlphaKey.ALPHA,
    PatternsFilterableGallerySortingService.SortFilterNumericKey.OPENNESS,
    PatternsFilterableGallerySortingService.SortFilterNumericKey.SHADE,
    PatternsFilterableGallerySortingService.SortFilterNumericKey.PRIVACY,
    PatternsFilterableGallerySortingService.SortFilterNumericKey.BIOMIMIC,
    PatternsFilterableGallerySortingService.SortFilterNumericKey.STRENGTH,
    PatternsFilterableGallerySortingService.SortFilterNumericKey.SMALL_HOLES,
    PatternsFilterableGallerySortingService.SortFilterNumericKey.MESH,
    PatternsFilterableGallerySortingService.SortFilterNewestKey.NEWEST,
];
// eslint-disable-next-line max-len
export const DEFAULT_SORT_KEY = PatternsFilterableGallerySortingService.SortFilterNewestKey.NEWEST;
export const DEFAULT_SORT_ORDER = PatternsFilterableGallerySortingService.OrderKey.DESC;
for (let i = 0; i < FILTER_CONFIG.length; i += 1) {
    const { key } = FILTER_CONFIG[i];
    const { label } = FILTER_CONFIG[i];
    const labelSuffixMap = {
        [PatternsFilterableGallerySortingService.OrderKey.DESC]: key === PatternsFilterableGallerySortingService.SortFilterAlphaKey.ALPHA ? 'A-Z' : 'high-low',
        [PatternsFilterableGallerySortingService.OrderKey.ASC]: key === PatternsFilterableGallerySortingService.SortFilterAlphaKey.ALPHA ? 'Z-A' : 'low-high',
    };
    SORT_FILTERS.push({
        key,
        order: PatternsFilterableGallerySortingService.OrderKey.DESC,
        label: key === PatternsFilterableGallerySortingService.SortFilterNewestKey.NEWEST ? label : `${label} (${labelSuffixMap[PatternsFilterableGallerySortingService.OrderKey.DESC]})`,
    });
    if (ASC_DESC_FILTER_KEYS.indexOf(key) !== -1) {
        SORT_FILTERS.push({
            key,
            order: PatternsFilterableGallerySortingService.OrderKey.ASC,
            label: key === PatternsFilterableGallerySortingService.SortFilterNewestKey.NEWEST ? label : `${label} (${labelSuffixMap[PatternsFilterableGallerySortingService.OrderKey.ASC]})`,
        });
    }
}
SORT_FILTERS.sort((a, b) => {
    const aindex = FILTER_ORDER.indexOf(a.key);
    const bindex = FILTER_ORDER.indexOf(b.key);
    const difference = aindex - bindex;
    if (difference === 0) {
        return a.order === PatternsFilterableGallerySortingService.OrderKey.DESC ? -1 : 1;
    }
    return difference;
});
