import Vue from 'vue';
import { PatternsFilterableGalleryClearFiltersButton, PatternsFilterableGalleryFilters, PatternsFilterableGalleryFilterTakeover, PatternsFilterableGalleryFreeFilter, PatternsFilterableGalleryPattern, PatternsFilterableGalleryPatterns, PatternsFilterableGallerySort, PatternsFilterableGallerySortTakeover, } from './_components';
Vue.component('PatternsFilterableGalleryFilters', PatternsFilterableGalleryFilters);
Vue.component('PatternsFilterableGalleryFreeFilter', PatternsFilterableGalleryFreeFilter);
Vue.component('PatternsFilterableGalleryClearFiltersButton', PatternsFilterableGalleryClearFiltersButton);
Vue.component('PatternsFilterableGallerySort', PatternsFilterableGallerySort);
Vue.component('PatternsFilterableGalleryPatterns', PatternsFilterableGalleryPatterns);
Vue.component('PatternsFilterableGalleryPattern', PatternsFilterableGalleryPattern);
Vue.component('PatternsFilterableGalleryFilterTakeover', PatternsFilterableGalleryFilterTakeover);
Vue.component('PatternsFilterableGallerySortTakeover', PatternsFilterableGallerySortTakeover);
export { default } from './PatternsFilterableGallery.vue';
