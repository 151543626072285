import Vue from 'vue';
export default Vue.extend({
    props: {
        config: {
            type: Object,
            default() {
                return {
                    more_button: false,
                };
            },
        },
    },
    computed: {
        patterns() {
            return this.$store.getters['patterns_filterable_gallery/display_patterns'];
        },
    },
});
