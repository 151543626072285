import Vue from 'vue';
import FinishPairingsToggle from './FinishPairingsToggle';
import GaLink from './GaLink';
import GatedLink from './GatedLink';
import Popover from './Popover';
import SubstructureModalTrigger from './SubstructureModalTrigger';
import TrackedFileLink from './TrackedFileLink';
Vue.directive('popover', Popover);
Vue.directive('GaLink', GaLink);
Vue.directive('GatedLink', GatedLink);
Vue.directive('FinishPairingsToggle', FinishPairingsToggle);
Vue.directive('SubstructureModalTrigger', SubstructureModalTrigger);
Vue.directive('TrackedFileLink', TrackedFileLink);
