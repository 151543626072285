import Vue from 'vue';
export default Vue.extend({
    /**
     * Reactive data
     */
    data() {
        return {
            media_status: {
                is_playing: false,
                is_paused: true,
                is_loading: false,
            },
        };
    },
    computed: {
        /**
         * Whether to show component in playing state
         */
        show_state_playing() {
            return this.media_status.is_playing;
        },
        /**
         * Whether to show component in loading state
         */
        show_state_loading() {
            return this.media_status.is_loading && this.media_status.is_playing;
        },
    },
    methods: {
        toggleMediaElement() {
            const mediaElement = this.getMediaElement();
            if (!mediaElement) {
                return;
            }
            if (mediaElement.paused) {
                mediaElement.play()
                    .catch(() => {
                    // prevent play interruption errors
                });
            }
            else {
                mediaElement.pause();
            }
        },
        /**
         * Get component audio element
         */
        getMediaElement() {
            return this.$refs.media;
        },
        /**
         * Play button click event handler
         */
        onButtonClick() {
            this.toggleMediaElement();
        },
        /**
         * Handle media 'play' event
         */
        onMediaPlay() {
            this.media_status.is_playing = true;
            this.media_status.is_paused = false;
        },
        /**
         * Handle media 'pause' event
         */
        onMediaPause() {
            this.media_status.is_playing = false;
            this.media_status.is_paused = true;
        },
        /**
         * Handle media 'waiting' event
         */
        onMediaWaiting() {
            this.media_status.is_loading = true;
        },
        /**
         * Handle media 'canplay' event
         */
        onMediaCanPlay() {
            this.media_status.is_loading = false;
        },
        /**
         * Pause the media
         */
        pause() {
            const mediaElement = this.getMediaElement();
            if (!mediaElement) {
                return;
            }
            mediaElement.pause();
        },
    },
});
