import mixins from 'vue-typed-mixins';
import PlaysMediaMixin from '../Mixins/PlaysMediaMixin';
// @vue/component
export default mixins(PlaysMediaMixin).extend({
    data() {
        return {
            show_controls: true,
            interaction_state: {
                mouse_in: false,
            },
        };
    },
    watch: {
        /**
         * Watch changes to whether the media is playing
         */
        'media_status.is_playing': function (isPlaying) {
            if (isPlaying) {
                // hide controls when media set to play
                this.hideControls();
            }
            else {
                // Show controls when media paused
                this.showControls();
            }
        },
    },
    methods: {
        /**
         * Show the player controls
         */
        showControls() {
            this.show_controls = true;
        },
        /**
         * Hide player controls
         */
        hideControls() {
            this.show_controls = false;
        },
        /**
         * Whether a mousein event fired represents a brand new hover interaction with the component
         */
        isNewMouseIn() {
            return !this.interaction_state.mouse_in;
        },
        /**
         * Handle click on media block of component
         */
        onMediaClick() {
            if (!this.show_controls) {
                this.showControls();
                return;
            }
            if (this.show_controls && this.media_status.is_playing) {
                this.hideControls();
            }
        },
        /**
         * Handle mouseenter event on media block
         */
        onMediaMouseEnter() {
            if (this.isNewMouseIn()) {
                this.interaction_state.mouse_in = true;
                if (!this.show_controls) {
                    this.showControls();
                }
            }
        },
        /**
         * Handle mouseleave event on media block
         */
        onMediaMouseLeave() {
            this.interaction_state.mouse_in = false;
            if (this.media_status.is_playing) {
                this.hideControls();
            }
        },
        /**
         * Get component media element
         */
        getMediaElement() {
            return this.$el.querySelector('video');
        },
    },
});
