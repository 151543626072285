import Vue from 'vue';
export default Vue.extend({
    props: {
        config: {
            type: Object,
            required: true,
        },
        state: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    computed: {
        filteredReps() {
            const filterState = parseInt(this.state, 10);
            if (!filterState) {
                return [];
            }
            return this.config.reps.filter((rep) => rep.stateIds.indexOf(filterState) !== -1);
        },
        displayReps() {
            if (this.filteredReps.length) {
                return this.filteredReps;
            }
            return [this.config.default];
        },
    },
    render() {
        const defaultSlot = this.$scopedSlots.default;
        const { displayReps, } = this;
        return defaultSlot({
            displayReps,
        });
    },
});
