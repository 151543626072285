import Vue from 'vue';
import { Modal, SiteTakeover } from './index';
export default Vue.extend({
    components: {
        Modal,
        SiteTakeover,
    },
    props: {
        modalClasses: {
            type: String,
            default: '',
        },
        breakpoint: {
            type: Number,
            default: 1200,
        },
    },
    data() {
        return {
            showOverlay: false,
            overlayComponent: 'modal',
        };
    },
    computed: {
        componentSlot() {
            if (this.overlayComponent === 'modal') {
                return 'content';
            }
            return 'default';
        },
    },
    methods: {
        openOverlay() {
            this.overlayComponent = window.innerWidth >= this.breakpoint ? 'modal' : 'siteTakeover';
            this.showOverlay = true;
        },
        closeOverlay() {
            this.showOverlay = false;
        },
        onOverlayClose() {
            this.closeOverlay();
        },
    },
});
