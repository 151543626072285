export default class ClickOut {
    constructor(parentNode, handler) {
        this.handler = handler;
        this.parentNode = parentNode;
        this.binding = this.handleDocumentClick.bind(this);
        document.addEventListener('click', this.binding);
    }
    static create(node, handler) {
        return new ClickOut(node, handler);
    }
    handleDocumentClick(e) {
        const { target } = e;
        if (target instanceof Node && this.parentNode.contains(target)) {
            return;
        }
        document.removeEventListener('click', this.binding);
        this.handler();
    }
}
