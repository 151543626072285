import axios from 'axios';
import Vue from 'vue';
export default Vue.extend({
    props: {
        route: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showNotice: true,
        };
    },
    methods: {
        acknowledge() {
            axios.post(this.route).then(() => {
                this.showNotice = false;
            });
        },
    },
});
