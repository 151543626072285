/* eslint-disable no-param-reassign */
import { StringHelpers } from '../Framework/Support/StringHelpers';
import { validateDateFormatted, validateDateFuture, validateEmail, validateMaxLength, validateRequired, validateRequiredIf, validateRequiredWith, } from './ValidationFunctions';
var capitalize = StringHelpers.capitalize;
export default class FormValidator {
    constructor(rules, messages) {
        this.validators = {
            required: (value) => validateRequired(value, false),
            email: (value) => validateEmail(value),
            date: (value) => validateDateFormatted(value),
            date_future: (value) => validateDateFuture(value),
            max: (value, max) => validateMaxLength(value, parseInt(max, 10)),
            required_with: (value, otherField, formData) => validateRequiredWith(value, otherField, formData),
            required_if: (value, args, formData) => {
                const split = args.split(',');
                const otherField = split[0];
                const otherValue = split[1] || '';
                return validateRequiredIf(value, otherField, otherValue, formData);
            },
        };
        this.rules = rules;
        this.messages = messages;
    }
    /**
     * Split a rule into its name and args
     *
     * e.g.: max:255 => max, 255
     */
    static getRuleNameArgs(ruleConf) {
        const split = ruleConf.split(':');
        return {
            ruleName: split[0],
            ruleArgs: split[1] || null,
        };
    }
    validate(fields, values) {
        const result = {};
        let firstError = null;
        const validationResult = fields.reduce((carry, field) => {
            const fieldRules = this.getFieldRules(field);
            const fieldValue = values[field.name];
            fieldRules.forEach((rule) => {
                const { ruleName, ruleArgs } = FormValidator.getRuleNameArgs(rule);
                if (typeof this.validators[ruleName] === 'function') {
                    if (!this.validators[ruleName](fieldValue, ruleArgs, values)) {
                        if (!carry[field.name]) {
                            carry[field.name] = {};
                        }
                        const message = this.getMessage(rule, field);
                        carry[field.name][rule] = message;
                        if (!firstError) {
                            firstError = message;
                        }
                    }
                }
            });
            return carry;
        }, result);
        return {
            errors: validationResult,
            valid: Object.keys(validationResult).length === 0,
            firstError,
        };
    }
    getFieldRules(field) {
        const base = this.rules[field.name];
        if (!base) {
            return [];
        }
        return base.split('|');
    }
    getMessage(rule, field) {
        const { ruleName, ruleArgs } = FormValidator.getRuleNameArgs(rule);
        const configured = this.messages[`${field.name}.${ruleName}`];
        if (configured) {
            return configured;
        }
        const rules = {
            required: 'Please provide your %s',
            email: '%s must be a valid email address',
            date: 'Please provide a date in MM/DD/YY format for your %s',
            date_future: '%s must be in the future',
            default: '%s is invalid',
            max: '%s must have %a characters or fewer',
            required_with: '%s must be filled out when %a is provided',
        };
        const ruleMessage = Object.prototype.hasOwnProperty.call(rules, ruleName)
            ? rules[ruleName]
            : rules.default;
        return capitalize(ruleMessage
            .replace('%s', field.label.toLowerCase())
            .replace('%a', ruleArgs || ''));
    }
}
