import Vue from 'vue';
import FormValidator from '../../Support/FormValidator';
import FinishSelectInput from './FinishSelectInput.vue';
export default Vue.extend({
    components: {
        FinishSelectInput,
    },
    filters: {
        /**
         * Field label display.  Add asterisk to required fields
         */
        label(field) {
            return `${field.label}${field.required ? '*' : ''}`;
        },
    },
    props: {
        config: {
            type: Object,
            required: true,
        },
    },
    /**
     * Reactive data
     */
    data() {
        return {
            allFields: [],
            formData: {},
            validator: new FormValidator(this.config.validation.rules, this.config.validation.messages),
            showErrors: false,
            submitting: false,
        };
    },
    computed: {
        /**
         * The configured fields for the component
         */
        fields() {
            return this.config.fields;
        },
        /**
         * The error for the given field, if there is one.
         */
        fieldError() {
            return (field) => {
                if (!this.showErrors) {
                    return null;
                }
                const validationErrors = this.validationResult.errors;
                const fieldErrors = validationErrors[field.name] || {};
                const errorKeys = Object.keys(fieldErrors);
                if (errorKeys.length) {
                    return fieldErrors[errorKeys[0]];
                }
                return null;
            };
        },
        /**
         * Loop of sections for general section of form
         */
        generalSections() {
            return [
                {
                    label: 'Material Samples',
                    fields: this.fields.materials,
                },
                {
                    label: 'Pattern Samples',
                    fields: this.fields.patterns,
                },
                {
                    label: 'Custom Sample Request',
                    fields: this.fields.request,
                },
                {
                    label: 'Recipient Information',
                    fields: this.fields.contact,
                },
                {
                    label: 'Shipping Information',
                    fields: this.fields.shipping.map((field) => this.transformRawField(field)),
                },
            ];
        },
        /**
         * Whether the form data is valid
         */
        valid() {
            return this.validationResult.valid;
        },
        /**
         * Current validation state of the form
         */
        validationResult() {
            return this.validator.validate(this.allFields, this.formData);
        },
    },
    /**
     * On create, get all available field names and
     * set their keys in formData to the initial field value
     */
    created() {
        this.allFields = Object.keys(this.config.fields)
            .reduce((carry, fieldset) => carry.concat(this.fields[fieldset]), []);
        this.allFields.forEach((field) => {
            this.$set(this.formData, field.name, field.value);
        });
    },
    methods: {
        /**
         * Perform transformations on a field based on current state
         */
        transformRawField(field) {
            const result = Object.assign({}, field);
            /**
             * Add asterisk to label of shipping account number when full kit is checked
             */
            if (field.name === 'shipping_account_number' && !!this.formData.full_kit) {
                result.required = true;
            }
            return result;
        },
        /**
         * The image associated with a given field
         */
        fieldImage(field) {
            if (typeof this.config.misc.field_images[field.name] !== 'undefined') {
                return this.config.misc.field_images[field.name].image;
            }
            return null;
        },
        /**
         * The image caption associated with a given field
         */
        fieldCaption(field) {
            if (typeof this.config.misc.field_images[field.name] !== 'undefined') {
                return this.config.misc.field_images[field.name].caption || null;
            }
            return null;
        },
        /**
         * Handle form submission event
         */
        onSubmit(e) {
            if (!this.valid) {
                this.showErrors = true;
                e.preventDefault();
                return;
            }
            this.submitting = true;
        },
        /**
         * Find a panel finish by ID
         */
        findPanelFinish(id) {
            return this.config.panel_finishes[id];
        },
        /**
         * Find a panel finish by ID
         */
        findStructureFinish(id) {
            return this.config.structure_finishes[id];
        },
    },
});
