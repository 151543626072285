import Vue from 'vue';
import WorkGalleryPin from '../Models/WorkGalleryPin';
import WorkGalleryTile from '../Models/WorkGalleryTile';
import WorkGalleryFilteringService from '../Services/WorkGalleryFilteringService';
import WorkGallerySortingService from '../Services/WorkGallerySortingService';
export default Vue.extend({
    props: {
        pinCaseStudies: {
            type: Array,
            required: true,
        },
        caseStudies: {
            type: Array,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        useFreeFilter: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            activeSortFilters: {
                application: this.filters.application[0].value,
                industry: this.filters.industry[0].value,
                sort: this.filters.sort[0].value,
                free: '',
            },
            pins: this.pinCaseStudies.map((item) => new WorkGalleryPin(item)),
            tiles: this.caseStudies.map((item) => new WorkGalleryTile(item)),
        };
    },
    computed: {
        mapCaseStudies() {
            return this.combinedCaseStudies;
        },
        mapCaseStudiesFiltered() {
            return this.combinedCaseStudiesFiltered;
        },
        combinedCaseStudies() {
            const result = [];
            return result.concat(this.pins, this.tiles);
        },
        combinedCaseStudiesFiltered() {
            return WorkGalleryFilteringService.filter(this.combinedCaseStudies, this.activeSortFilters);
        },
        galleryFiltered() {
            const result = [];
            this.combinedCaseStudiesFiltered.forEach((item) => {
                if (item instanceof WorkGalleryTile) {
                    result.push(item);
                }
            });
            return result;
        },
        visibleCaseStudies() {
            return WorkGallerySortingService.sort(this.galleryFiltered, this.activeSortFilters.sort);
        },
    },
});
