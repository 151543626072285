import { TrackedFileLinkReporter } from '../Support/TrackedFileLinkReporter';
import { GatedLink } from './GatedLink';
const Directive = {
    inserted(el, binding, vNode) {
        let gateDirective = null;
        if (vNode.data && vNode.data.directives) {
            for (let i = 0; i < vNode.data.directives.length; i += 1) {
                const directive = vNode.data.directives[i];
                if (directive.name === 'gated-link') {
                    gateDirective = directive;
                    break;
                }
            }
        }
        const { value } = binding;
        if (!value) {
            return;
        }
        el.addEventListener('click', () => {
            if (gateDirective) {
                if (!GatedLink.isOpen(gateDirective.value)) {
                    return;
                }
            }
            TrackedFileLinkReporter.report(value);
        });
    },
};
export default Directive;
