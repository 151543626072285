export class GaEventReporter {
    static report(event) {
        if (typeof window.ga === 'function') {
            ga('send', 'event', event.category, event.action, event.label);
        }
        if (typeof window.gtag === 'function') {
            gtag('event', event.action || '(not set)', {
                event_category: event.category || '(not set)',
                event_label: event.label || '(not set)',
            });
        }
    }
}
