const Directive = {
    inserted(el) {
        const root = el.closest('[data-pairings-root]');
        if (root) {
            const swatches = root.querySelectorAll('.finish-swatch.has-pairing');
            el.addEventListener('click', () => {
                Array.from(swatches)
                    .forEach((swatch) => {
                    swatch.classList.toggle('is-pairing-active');
                });
            });
        }
    },
};
export default Directive;
