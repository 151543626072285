import Vue from 'vue';
// @vue/component
export default Vue.extend({
    props: {
        /**
         * The finish to apply to the preview
         */
        active_finish: {
            type: Object,
            required: false,
        },
        /**
         * The finish categories available to the component
         */
        categories: {
            type: Array,
            required: true,
        },
        /**
         * Link to finishes page
         */
        link: {
            type: String,
            required: false,
        },
    },
    /**
     * Reactive data
     */
    data() {
        return {
            hover_finish: null,
            tooltip_delay: '{"show": 200, "hide": 0}',
        };
    },
    methods: {
        /**
         * Whether a finish is the active finish
         */
        isActive(finish) {
            return this.active_finish && finish.id === this.active_finish.id;
        },
        /**
         * Handle click on a finish
         */
        onFinishClick(finish, e) {
            const { target } = e;
            if (target instanceof HTMLButtonElement) {
                target.blur();
            }
            this.$emit('choose-finish', finish);
        },
    },
});
