import Vue from 'vue';
export default Vue.extend({
    props: {
        buttonText: {
            type: String,
            default: 'Load more',
        },
        baseDisplayCount: {
            type: Number,
            required: true,
        },
        incrementAmount: {
            type: Number,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        showAll: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            displayCount: 0,
        };
    },
    computed: {
        showLoadMore() {
            return this.displayCount < this.items.length;
        },
        limitedItems() {
            return this.items.slice(0, this.displayCount);
        },
    },
    watch: {
        items(newV, oldV) {
            if (newV.length !== oldV.length) {
                this.reset();
            }
        },
    },
    mounted() {
        this.reset();
    },
    methods: {
        reset() {
            this.displayCount = this.showAll ? this.items.length : this.baseDisplayCount;
        },
        showMore() {
            this.displayCount += this.incrementAmount;
        },
    },
});
