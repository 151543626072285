import tippy from 'tippy.js';
const Directive = {
    bind(el) {
        const content = el.dataset.content || '';
        const placement = el.dataset.placement || 'top';
        const appendTo = el.dataset.appendTo || document.body;
        tippy(el, {
            // trigger: 'click',
            // hideOnClick: true,
            content,
            placement,
            appendTo,
        });
    },
};
export default Directive;
