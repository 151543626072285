import { PatternsFilterableGalleryFilteringService } from '../_services/_contracts';
export const DEFAULT_FILTERS = {
    openness_percentage: {
        min: 10,
        max: 60,
    },
    shade: {
        min: 1,
        max: 10,
    },
    privacy: {
        min: 1,
        max: 10,
    },
    biomimicry: {
        min: 1,
        max: 10,
    },
    structural_strength: {
        min: 1,
        max: 10,
    },
    small_hole_size: {
        min: 1,
        max: 10,
    },
    mesh: {
        min: 1,
        max: 10,
    },
    name: '',
};
//
export const FILTER_FIELDS = [
    {
        label: 'Openness Percentage',
        key: PatternsFilterableGalleryFilteringService.FilterKey.OPENNESS,
    },
    {
        label: 'Shade',
        key: PatternsFilterableGalleryFilteringService.FilterKey.SHADE,
    },
    {
        label: 'Privacy',
        key: PatternsFilterableGalleryFilteringService.FilterKey.PRIVACY,
    },
    {
        label: 'Biomimicry',
        key: PatternsFilterableGalleryFilteringService.FilterKey.BIOMIMIC,
    },
    {
        label: 'Structural Strength',
        key: PatternsFilterableGalleryFilteringService.FilterKey.STRENGTH,
    },
    {
        label: 'Small Hole Size',
        key: PatternsFilterableGalleryFilteringService.FilterKey.SMALL_HOLES,
    },
    {
        label: 'Use As MESH',
        key: PatternsFilterableGalleryFilteringService.FilterKey.MESH,
    },
];
