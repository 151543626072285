import Vue from 'vue';
export default Vue.extend({
    props: {
        field: {
            type: Object,
            required: true,
        },
        option: {
            type: Object,
            required: true,
        },
        value: {
            type: Array,
            required: true,
        },
        finish: {
            type: Object,
            required: true,
        },
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
});
