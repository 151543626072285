import Vue from 'vue';
export default Vue.extend({
    data() {
        return {
            success: false,
            submitError: false,
            submitting: false,
            formData: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                role: '',
                zipCode: '',
                structuralCalculations: false,
            },
        };
    },
    computed: {
        options() {
            return this.$store.state.system_configuration.contactOptions;
        },
        showModal: {
            get() {
                return this.$store.state.system_configuration.requestQuoteActive;
            },
            set(val) {
                this.$store.commit('system_configuration/setShowModal', val);
            },
        },
    },
    watch: {
        formData: {
            deep: true,
            handler() {
                this.submitError = false;
            },
        },
        showModal(value) {
            if (!value) {
                this.reset();
            }
        },
    },
    mounted() {
        document.body.appendChild(this.$el);
    },
    methods: {
        reset() {
            this.$set(this, 'formData', {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                role: '',
                zipCode: '',
                structuralCalculations: false,
            });
            this.submitError = false;
            this.success = false;
            this.submitting = false;
        },
        submit() {
            this.submitting = true;
            this.submitError = false;
            this.$store.dispatch('system_configuration/requestQuote', Object.assign({}, this.formData))
                .then((response) => {
                this.success = response;
                this.submitting = false;
            })
                .catch((error) => {
                this.submitError = error;
                this.submitting = false;
            });
        },
    },
});
