import Vue from 'vue';
export default Vue.extend({
    props: {
        image: {
            type: Object,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        attach() {
            this.$mount();
            document.body.appendChild(this.$el);
        },
        close() {
            this.$emit('close');
        },
        open() {
            this.show = true;
        },
    },
});
