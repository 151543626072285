import mixins from 'vue-typed-mixins';
import SelectionModuleMixin from '../Mixins/SelectionModuleMixin';
const extendedVue = mixins(SelectionModuleMixin);
// @vue/component
export default extendedVue.extend({
    computed: {
        categories() {
            return this.$store.state.system_configuration.options.panelFinishes;
        },
        selection: {
            get() {
                return this.$store.state.system_configuration.selections.panelFinish;
            },
            set(value) {
                this.$store.commit('system_configuration/setPanelFinishSelection', value);
            },
        },
    },
});
