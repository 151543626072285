import Vue from 'vue';
export default Vue.extend({
    computed: {
        moduleTooltip() {
            return this.$store.state.system_configuration.moduleTooltips.system || false;
        },
        options() {
            return this.$store.state.system_configuration.options.systems;
        },
        selection: {
            get() {
                return this.$store.state.system_configuration.selections.system;
            },
            set(value) {
                this.$store.commit('system_configuration/setSystemSelection', value);
            },
        },
    },
});
