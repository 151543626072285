import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
export default Vue.extend({
    computed: Object.assign(Object.assign(Object.assign({}, mapGetters('system_configuration', ['activeEstimatedCost'])), mapState('system_configuration', ['application'])), { outputString() {
            let result = '';
            const activeEstimate = this.activeEstimatedCost;
            if (activeEstimate) {
                result = `$${activeEstimate.min} - $${activeEstimate.max}`;
                if (activeEstimate.suffix) {
                    result += ` ${activeEstimate.suffix}`;
                }
            }
            return result;
        },
        title() {
            return this.application === 'shade'
                ? 'Estimated Shade Structure Cost'
                : 'Estimated Screen System Cost';
        } }),
});
