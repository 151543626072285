import Vue from 'vue';
export default Vue.extend({
    props: {
        value: {
            type: Object,
            required: true,
        },
        availableFilters: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            formData: {
                application: this.value.application,
                industry: this.value.industry,
                sort: this.value.sort,
                free: '',
            },
            sorts: ['sort'],
            filters: ['application', 'industry'],
        };
    },
    watch: {
        formData: {
            deep: true,
            handler() {
                this.$emit('input', Object.assign({}, this.formData));
            },
        },
    },
});
